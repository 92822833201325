import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "../Dashboard/Title";
import moment from "moment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { calcularTotal } from "../../../utils/total";
import AdminContext from "../../../context/admin/AdminContext";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import {
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slide,
  Typography,
  Collapse,
  Box,
  LinearProgress,
  Grid,
  Button,
  Paper,
  Divider,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { Axios } from "../../../config/axios";
import {
  GET_ORDERS_STORE,
  GET_ORDERS_DETAILS,
  GET_OPTIONS_DETAILS,
  GET_ORDERS_FILTER_DATE,
} from "../../../constants";

import toast from "toasted-notes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Orders() {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [orders, setOrders] = React.useState([]);
  const [details, setDetails] = useState([]);

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [beforeDate, setBeforeDate] = React.useState(new Date());
  const [nextDate, setNextDate] = React.useState(new Date());
  const [pedido, setPedido] = React.useState(null);
  const [client, setClient] = React.useState({});
  const [total, setTotal] = React.useState(0);

  const componentRef = useRef();

  const handleDateChange = (date) => {
    console.log(date);
    console.log(moment(date).format("YYYY-MM-DD"));
    const dates = moment(date).format("YYYY-MM-DD").split("-");
    console.log(dates);
  };

  const filterDate = async () => {
    const valid = moment(moment(nextDate).format("YYYY-MM-DD")).isAfter(
      moment(beforeDate).format("YYYY-MM-DD")
    );
    const x = moment(nextDate).diff(moment(beforeDate), "minute");
    try {
      if (valid || x == 0) {
        console.log("valido");
        const initialDate = moment(beforeDate).format("YYYY-MM-DD");
        const finalDate = moment(nextDate).format("YYYY-MM-DD");
        Axios.get(
          `${GET_ORDERS_FILTER_DATE}${admin.codigo}/${initialDate}/${finalDate}`
        )
          .then((res) => {
            console.log(res.data);
            setLoading(false);
            setOrders(res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        toast.notify("Las fechas no son validas");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOrders = async () => {
    Axios.get(`${GET_ORDERS_STORE}${admin.codigo}`)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        setOrders(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getOrdersDetails = async (item) => {
    Axios.get(`${GET_ORDERS_DETAILS}${item.NumeroPedido}`)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        setDetails(res.data);
        setPedido(item);
        setOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  if (loading)
    return (
      <>
        <LinearProgress color="primary" style={{ margin: "1rem" }} />
      </>
    );

  return (
    <React.Fragment>
      {/* <Title>Recent Orders</Title> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        maxWidth="xl"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "1rem",
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              style={{ color: "#707070", marginRight: "2rem" }}
              noWrap
            >
              Detalle Pedido
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer style={{ maxHeight: 500, width: "100%" }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell/>
                  <TableCell style={{ minWidth: 180 }}>Nombre</TableCell>
                  <TableCell style={{ minWidth: 120 }}>Precio</TableCell>
                  <TableCell style={{ minWidth: 140, textAlign: "center" }}>
                    Cantidad
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>Sub Total</TableCell>
                  <TableCell style={{ minWidth: 400 }}>Observación</TableCell>
                  {/* <TableCell style={{ minWidth: 180 }}><i class="fas fa-tachometer-alt-slowest    "></i></TableCell> */}
                </TableRow>
              </TableHead>
              {details.map((row,i) => (
            <Row key={i} row={row} />
          ))}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="center"
            style={{ margin: "1rem", minHeight: "100px" }}
          ></Grid>
        </DialogActions>
      </Dialog>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="center" style={{ margin: "2rem" }} spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              id="date-picker-inline"
              label="Fecha Inicial"
              value={beforeDate}
              onChange={(date) => setBeforeDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              id="date-picker-inline"
              label="Fecha Final"
              value={nextDate}
              onChange={(date) => setNextDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#799FCB" }}
              onClick={filterDate}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      {orders && orders.length > 0 ? (
        <TableContainer style={{ maxHeight: 470 }}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 170 }}>Acciones</TableCell>
                <TableCell>N° Pedido</TableCell>
                <TableCell>N° Orden</TableCell>
                <TableCell style={{ minWidth: 180 }}>Fecha</TableCell>
                <TableCell style={{ minWidth: 140 }}>Precio</TableCell>
                <TableCell style={{ minWidth: 180 }}>Observación</TableCell>
                <TableCell style={{ minWidth: 100 }}>Estado</TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>             
              {orders.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="default"
                      startIcon={<PageviewOutlinedIcon />}
                      onClick={() => getOrdersDetails(row)}
                    >
                      Ver Detalles
                    </Button>
                  </TableCell>
                  <TableCell
                    style={{ color: "#2e1156", cursor: "pointer" }}
                    component="th"
                    scope="row"
                  >
                    {row.NumeroPedido}
                  </TableCell>
                  <TableCell
                    style={{ color: "#2e1156", cursor: "pointer" }}
                    component="th"
                    scope="row"
                  >
                    {row.Id}
                  </TableCell>
                  <TableCell>
                    {moment(row.Fecha).format("YYYY-MM-D, h:mm a")}
                  </TableCell>
                  <TableCell>                    
                      $ {row.Valor.toFixed(2)}  
                  </TableCell>
                  <TableCell>
                    {row.Observacion ? row.Observacion : "No hay observaciones"}
                  </TableCell>
                  <TableCell>{row.Descripcion}</TableCell>                  
                </TableRow>
              ))}
            </TableBody>            
          </Table>
        </TableContainer>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p>No hay registros</p>
        </div>
      )}

      {/* <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          Ver más pedidos
        </Link>
      </div> */}
    </React.Fragment>
  );
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [Options, setOptions] = useState([])
  const classes = useRowStyles();

  const getOpciones=async()=>{
    Axios.get(GET_OPTIONS_DETAILS+row.IdDetalle)
    .then((res) => {
      setOptions(res.data)
    })
    .catch((err) => {
      console.log(err);
    });
  }


  useEffect(() => {
    getOpciones()
  }, [])

  return (
    <React.Fragment>
       <TableRow >
       <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
                      <TableCell>{row.Nombre}</TableCell>
                      <TableCell>                        
                           $ {(row.Precio).toFixed(2)}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {row.Cantidad}
                      </TableCell>
                      <TableCell>                      
                          $ {(row.Precio * row.Cantidad).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {row.Observacion
                          ? row.Observacion
                          : "No hay observaciones"}
                      </TableCell>                      
                    </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Opciones del producto
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Seccion</TableCell>
                    <TableCell>Opcion</TableCell>
                    <TableCell>Precio</TableCell>                                     
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Options.map((detail,i) => (
                    <TableRow key={i}>
                        <TableCell>
                        {detail.seccion}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {detail.Nombre}
                      </TableCell>
                    
                      <TableCell>$ {Number.parseFloat(detail.precio).toFixed(2)}</TableCell>
                     
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}