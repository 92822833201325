import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  TextField,
  Button,
  Switch,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@material-ui/core";
import moment from "moment";
import Select from "react-dropdown-select";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Axios } from "../../../config/axios";
import {
  GET_CATEGORIES,
  CREATE_CATEGORY,
  UPDATE_CATEGORY_STATE,
  GET_STORES,
} from "../../../constants";

import toast from "toasted-notes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
    margin: "1rem",
  },
  form: {
    margin: "2rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "55%",
  },
}));

export const Categories = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [cat, setCat] = useState("");
  const [nombre, setNombre] = useState("");
  const [err, setErr] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  const [tipo, setTipo] = React.useState("");

  const [tipos, setTipos] = useState([
    {
      label: "Compleja",
      value: 1,
    },
    {
      label: "Simple",
      value: 0,
    },
  ]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    Axios.get(`${GET_CATEGORIES}?type=${0}`)
      .then((res) => {
        console.log(res.data);
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (row) => {
    console.log(row);
    setOpen(true);
    setMsg(row.Estado == 1 ? "Inactivar" : "Activar");
    setCat(row);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErr(false);
    console.log(nombre);
    if (nombre != "") {
      Axios.post(`${CREATE_CATEGORY}?nombre=${nombre}`)
        .then((res) => {
          setNombre("");
          getCategories();
          toast.notify("Categoría Creada Correctamente");
        })
        .catch((err) => {
          console.log(err);
          toast.notify("Hubo un error al crear la categoría");
        });
    } else {
      setErr(true);
    }
  };

  const handleState = () => {
    console.log("entra")
    Axios.post(UPDATE_CATEGORY_STATE, {
      codigo: cat.Codigo,
      estado: cat.Estado == 1 ? 0 : 1,
    })
      .then((res) => {
        console.log(res);
        getCategories();
        toast.notify(
          `Categoria ${
            cat.Estado == 0 ? "Activada" : "Inactivada"
          } correctamente`
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Paper className={classes.paper}>
              <TableContainer style={{ maxHeight: 470 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 70 }}>Codigo</TableCell>
                      <TableCell style={{ minWidth: 140 }}>Nombre </TableCell>
                      <TableCell style={{ minWidth: 140 }}>Estados</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categories.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          style={{ color: "#2e1156", cursor: "pointer" }}
                          component="th"
                          scope="row"
                        >
                          {row.Codigo}
                        </TableCell>
                        <TableCell>{row.Nombre}</TableCell>
                        <TableCell>
                          <Switch
                            checked={row.Estado == 1 ? true : false}
                            onChange={() => handleChange(row)}
                            color="primary"
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper className={classes.paper}>
              <Typography
                component="h2"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                Nueva Categoría
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <TextField
                  id="standard-basic"
                  label="Nombre"
                  variant="outlined"
                  onChange={(e) => setNombre(e.target.value)}
                  value={nombre}
                  error={err}
                  helperText={err && "Campo obligatorio"}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#AC7A47",
                    color: "white",
                    borderRadius: "7px",
                  }}
                  className={classes.submit}
                >
                  Registrar Categoría
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Vas a {msg} una Categoría
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Estas seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleState} color="primary">
            Si
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
