import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputLabel,
  Paper,
  Typography,
  IconButton,
  TextField,
  Button,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  FormLabel,
  FormControlLabel,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Switch,
} from "@material-ui/core";
import { Axios } from "../../../../config/axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { CREATE_PRODUCT, GET_SECCIONES,UPDATE_PRODUCT,GET_SECCIONES_PRODUCT,GET_CAT_OPCIONES } from "../../../../constants";
import Select from "react-dropdown-select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import toast from "toasted-notes";
var allOpciones=[]


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 50,
  },
  paper: {
    padding: "1rem",
    margin: theme.spacing(4, 4),
    display: "grid",
    gridTemplateColumns: "1.9fr 0.1fr",
    alignItems: "center",
    justifyContent: "center",
  },
  formT: {
    padding: "1rem",
    margin: theme.spacing(4, 4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  formC: {
    padding: "1rem",
    margin: theme.spacing(4, 4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  formL: {
    padding: "1rem",
    margin: theme.spacing(4, 4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  formA: {
    padding: "1rem",
    margin: theme.spacing(4, 4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  formS: {
    padding: "1rem",
    margin: theme.spacing(4, 4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  formAd: {
    padding: "1rem",
    margin: theme.spacing(4, 4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const ProductFormComplex = ({ setForm, product, codCat, setComplex }) => {
  const classes = useStyles();

 

  // product
  const [nombre, setNombre] = useState(product? product.Nombre : "");
  const [descripcion, setDescripcion] = useState(product? product.Descripcion : "");
  const [precio, setprecio] = useState(0);

  // opciones de tamaño
  const [opcionesT, setOpcionesT] = useState([]);
  const [opcionesSeleccionadasT, setOpcionesSeleccionadasT] = useState([]);
  const [opcionT, setOpcionT] = useState({
    nombre: "",
    precio: 0,
    seccionId: 0,
    opcionId: 0,
  });
  const [formT, setFormT] = useState(false);
  const [sinT, setSinT] = useState(false);

  // opciones de cafe
  const [opcionesC, setOpcionesC] = useState([]);
  const [checkAllC, setCheckAllC] = React.useState(false);
  const [opcionesSeleccionadasC, setOpcionesSeleccionadasC] = useState([]);
  const [opcionC, setOpcionC] = useState({
    nombre: "",
    precio: 0,
    seccionId: 0,
    opcionId: 0,
  });
  const [formC, setFormC] = useState(false);

  // opciones de leche
  const [tam, setTam] = useState("");
  const [opcionesL, setOpcionesL] = useState([]);
  const [opcionesSeleccionadasL, setOpcionesSeleccionadasL] = useState([]);
  const [opcionL, setOpcionL] = useState({
    nombre: "",
    precio: 0,
    seccionId: 0,
    opcionId: 0,
    tipo: 0,
  });
  const [formL, setFormL] = useState(false);

  // opciones de azucar
  const [opcionesA, setOpcionesA] = useState([]);
  const [checkAllA, setCheckAllA] = React.useState(false);
  const [opcionesSeleccionadasA, setOpcionesSeleccionadasA] = useState([]);
  const [opcionA, setOpcionA] = useState({
    nombre: "",
    precio: 0,
    seccionId: 0,
    opcionId: 0,
  });
  const [formA, setFormA] = useState(false);

  // opciones de sabor
  const [opcionesS, setOpcionesS] = useState([]);
  const [checkAllS, setCheckAllS] = React.useState(false);
  const [opcionesSeleccionadasS, setOpcionesSeleccionadasS] = useState([]);
  const [OpcionesCat, setOpcionesCat] = useState([])

  // adicionales
  const [adicionales, setAdicionales] = useState([]);
  const [adicional, setAdicional] = useState({
    nombre: "",
    precio: 0,
    seccionId: 0,
    opcionId: 0,
  });
  const [formAd, setFormAd] = useState(false);

  

  const CatOpciones=async ()=>{
    Axios.get(GET_CAT_OPCIONES)
      .then((res) => {
        console.log("CatOpciones ", res.data);
        var array=Array.from(res.data)
        // var cat=array.filter((e)=>e.Nombre!=="Adicionales")
        setOpcionesCat(array)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getSecciones = async () => {
    Axios.get(`${GET_SECCIONES}`)
      .then((res) => {
        console.log(res.data);
        let data = res.data;
        let ot = [];
        let oc = [];
        let ol = [];
        let oa = [];
        let os = [];
        let ad = [];
        data.forEach((s) => {
          if (s.Id == 1) {
            ot.push(s);
          } else if (s.Id == 2) {            
            oc.push(s);
          } else if (s.Id == 3) {
            ol.push(s);
          } else if (s.Id == 4) {
            oa.push(s);
          } else if (s.Id == 5) {
            os.push(s);
          }
        });
        console.log("Opciones Cafe",oc);
        setOpcionesT(ot);
        setOpcionesC(oc);
        setOpcionesL(ol);
        setOpcionesA(oa);
        setOpcionesS(os);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeccionesProducto = async () => {
    Axios.get(`${GET_SECCIONES_PRODUCT}${product.Codigo}`)
      .then((res) => {
        console.log("secciones del producto ",res.data);
        let data = res.data;
        let ot = [];
        let oc = [];
        let ol = [];
        let oa = [];
        let os = [];
        let ad = [];
        if(!product){
      console.log("entraaa");
      oa.push({Id: 4,
        OpcionId: 14,
        nombre: "Sin Azúcar",
        precio: 0,
        seccionId: 4})
    }
        data.forEach((s) => {
          if (s.SeccionId == 1) {
            ot.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio,
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            });
          } else if (s.SeccionId == 2) {
            oc.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio,
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            });
          } else if (s.SeccionId == 3) {
            ol.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio,
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            });
          } else if (s.SeccionId == 4) {
            oa.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio,
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            });
          } else if (s.SeccionId == 5) {
            os.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio,
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            });
          }
          else if (s.SeccionId === 7) {
            console.log("adicionales ",s);
          }
        });
        console.log(os);
        console.log("secciones del Tamaño ",ot);       
        setOpcionesSeleccionadasT(ot);
        setOpcionesSeleccionadasC(oc);
        setOpcionesSeleccionadasL(ol);
        setOpcionesSeleccionadasA(oa);
        console.log("secciones azucar",oa );
        setOpcionesSeleccionadasS(os);
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectALL=()=>{
    console.log("tamano ",opcionesSeleccionadasS);
    if(opcionesSeleccionadasS.length===opcionesS.length){
      setCheckAllS(true)
    }else{
      setCheckAllS(false)
    }
    if(opcionesSeleccionadasA.length===opcionesA.length){
      setCheckAllA(true)
    }
    if(opcionesSeleccionadasC.length===opcionesC.length){
      setCheckAllC(true)
    }
  }

  useEffect(() => {
    CatOpciones();
    getSecciones();
    getSeccionesProducto();
    return () => {
       
      allOpciones=[]
   }
    //selectALL()
  }, []);

  const onSubmit = (e) => {
    let cantidadProductos = parseInt(localStorage.getItem("countProducts"));
    e.preventDefault();
    // console.log(opcionesSeleccionadasT);
    // console.log(opcionesSeleccionadasC);
    // console.log(opcionesSeleccionadasL);
    // console.log(opcionesSeleccionadasA);
    // console.log(opcionesSeleccionadasS);
    // console.log(adicionales);

    let opciones = opcionesSeleccionadasT.concat(
      opcionesSeleccionadasC.concat(
        opcionesSeleccionadasL
          .concat(opcionesSeleccionadasA)
          .concat(opcionesSeleccionadasS)
          .concat(adicionales)
      )
    );


    if(nombre.trim()===""){
      toast.notify("El nombre es obligatorio");
      return
    }
    if(descripcion.trim()===""){
      toast.notify("La descripcion es obligatoria");
      return
    }

    
    let data = {
      nombre,
      precio:0.00,
      codCat:product? product.CodCategoria : codCat,
      opciones:allOpciones,
      orden: cantidadProductos + 1,
      descripcion
    };
    
    if(!product){      
      setTimeout(() => {
        return Axios.post(CREATE_PRODUCT, data)
        .then((res) => {
          toast.notify(res.data.Mensaje);
          allOpciones=[];
          setForm(false);
          setComplex(false);
        })
        .catch((err) => {
          console.log(err);
          toast.notify("No se pudo crear el producto");
        });
      }, 1000);
    }else{
      setTimeout(() => {
        data.Codigo=product.Codigo
        Axios.post(UPDATE_PRODUCT, data)
        .then((res) => {
          toast.notify(res.data.Mensaje);
          allOpciones=[];
          setForm(false);
          setComplex(false);
        })
        .catch((err) => {
          console.log(err);
          toast.notify("No se pudo Actualizar el prodcuto");
        });
      }, 1000);
      
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              component="h3"
              variant="h6"
              style={{ color: "#707070" }}
              noWrap
            >
              {product ? "Actualizar Producto" : "Nuevo Producto"}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Paper className={classes.paper}>
            <TextField
              id="standard-basic"
              label="Nombre"
              name="nombre"
              variant="outlined"
              autoFocus
              onChange={(e) => setNombre(e.target.value)}
              value={nombre}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Paper className={classes.paper}>
            <TextField
              multiline
              rows={1}
              rowsMax={10}
              id="standard-basic"
              label="Descripcion"
              name="descripcion"
              variant="outlined"
              inputProps={{
                maxLength: 250,
              }}
              autoFocus
              onChange={(e) => setDescripcion(e.target.value)}
              value={descripcion}
            />
          </Paper>
        </Grid>

    

          {
             OpcionesCat.length!==0?
             OpcionesCat.map((cat,i)=>(
              cat.Tipo===2?
              <Opciones1 key={i} cat={cat} product={product} />
              :              
              <Opciones2 key={i} cat={cat} product={product}  />
             ))
            :null
          }         
               

        {/* <Grid item xs={12} md={6} lg={6}>
          <Paper className={formAd ? classes.formAd : classes.paper}>
            {formAd && (
              <div style={{ marginTop: "11px" }}>
                <TextField
                  id="standard-basic"
                  label="Nombre"
                  variant="outlined"
                  name="nombre"
                  type="text"
                  onChange={(e) => {
                    if (e.target.value.length == 0) return;
                    let data = {
                      nombre: e.target.value,
                    };
                    setAdicional({ ...adicional, ...data });
                  }}
                />
              </div>
            )}

            {formAd && (
              <div style={{ marginTop: "11px" }}>
                <TextField
                  id="standard-basic"
                  label="precio"
                  variant="outlined"
                  name="precio"
                  type="number"
                  onChange={(e) => {
                    if (e.target.value.length == 0) return;
                    let data = {
                      precio: parseFloat(e.target.value).toFixed(2),
                    };
                    setAdicional({ ...adicional, ...data });
                  }}
                />
              </div>
            )}

            {formAd && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#AC7A47",
                  color: "white",
                  marginTop: "10px",
                }}
                onClick={() => {
                  adicional.opcionId = -1;
                  adicional.seccionId = 7;
                  console.log("opcion ", adicional);
                  const found = adicionales.find(
                    (a) => a.nombre == adicional.nombre
                  );
                  console.log(found);
                  allOpciones.push(adicional)
                  if (!found) {
                    setAdicionales([...adicionales, adicional]);
                    setFormAd(false);
                  } else {
                    alert("ya tienes este adicional");
                  }
                }}
              >
                Guardar
              </Button>
            )}

            {formAd && (
              <Button
                style={{ marginTop: "10px" }}
                variant="contained"
                onClick={() => setFormAd(false)}
              >
                Cancelar
              </Button>
            )}

            {!formAd && <h3>Adicionales</h3>}

            {!formAd && (
              <IconButton
                aria-label="delete"
                style={{
                  backgroundColor: "#AC7A47",
                  color: "white",
                }}
                onClick={() => setFormAd(true)}
              >
                <AddCircleIcon fontSize="small" />
              </IconButton>
            )}

            {!formAd && adicionales.length > 0 && (
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell align="right">precio</TableCell>
                      <TableCell align="right">Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adicionales.map((row) => (
                      <TableRow key={row.nombre}>
                        <TableCell component="th" scope="row">
                          {row.nombre}
                        </TableCell>
                        <TableCell align="right">{row.precio}</TableCell>
                        <TableCell align="right" style={{ cursor: "pointer" }}>
                          <DeleteIcon
                            onClick={() => {
                              let op = adicionales;
                              op = op.filter((o) => o.nombre !== row.nombre);
                              setAdicionales(op);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>        */}
      </Grid>
      <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#AC7A47", color: "white",width:"50%" }}
              onClick={onSubmit}
            >
              {product ? "Actualizar Producto" : "Registrar Producto"}
            </Button>
        </div>
    </div>
  );
};


const Opciones1=({cat,product})=>{
  const [form, setForm] = useState(false);
  const [opciones, setopciones] = useState(false);
  const [checkAll, setCheckAll] = React.useState(false);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState(false);

  //FUNCIONES PARA SELECIONAR CHECKS AZUCAR
  const handleToggle = (value) => () => {
    const currentIndex = opcionesSeleccionadas.findIndex((c) => c.OpcionId == value.OpcionId);
    const currentIndex2 = allOpciones.findIndex((c) => c.OpcionId == value.OpcionId);
    console.log(currentIndex);
    const newChecked = [...opcionesSeleccionadas];
    if (currentIndex === -1) {
      newChecked.push({
        Id: value.Id,
        nombre: value.Nombre,
        seccionId: value.Id,
        OpcionId: value.OpcionId,
        precio:0
      });
      allOpciones.push(
        {
          Id: value.Id,
          nombre: value.Nombre,
          seccionId: value.Id,
          OpcionId: value.OpcionId,
          precio:0
        }
      )
    } else {
      newChecked.splice(currentIndex, 1);
      allOpciones.splice(currentIndex2, 1);
    }
    console.log("allOpciones 3",allOpciones);
    setOpcionesSeleccionadas(newChecked);
    if(newChecked.length===opciones.length){
      setCheckAll(true)
    }else{
      setCheckAll(false)
    }
  };
  const SelectAll=()=>{
    const newChecked = [...opcionesSeleccionadas];
    opciones.map((opcion)=>{
      const currentIndex = newChecked.findIndex((c) => c.OpcionId == opcion.OpcionId);
      const currentIndex2 = allOpciones.findIndex((c) => c.OpcionId == opcion.OpcionId);          
      if (currentIndex === -1) {
        if(!checkAll){
            newChecked.push({
              Id: opcion.Id,
        nombre: opcion.Nombre,
        seccionId: opcion.Id,
        OpcionId: opcion.OpcionId,
        precio:0
            });

            allOpciones.push({
              Id: opcion.Id,
        nombre: opcion.Nombre,
        seccionId: opcion.Id,
        OpcionId: opcion.OpcionId,
        precio:0
            });
        }
      } else {        
        if(checkAll){
          newChecked.splice(currentIndex, 1);
          allOpciones.splice(currentIndex2, 1);
        }
      }      
    })
    console.log("allOpciones 4",allOpciones);
    console.log(newChecked);
    setOpcionesSeleccionadas(newChecked);
    setCheckAll(!checkAll)
  }

  const getSecciones = async () => {
    Axios.get(`${GET_SECCIONES}`)
      .then((res) => {
        console.log(res.data);
        let data = res.data;
        let ot = [];
        data.forEach((s) => {
          if (s.Id == cat.Id) {
            ot.push(s);
          } 
        });
        setopciones(ot);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeccionesProducto = async () => {
    Axios.get(`${GET_SECCIONES_PRODUCT}${product.Codigo}`)
      .then((res) => {
        console.log("secciones del producto ",res.data);
        let data = res.data;
        let ot = [];
        /* if(!product){
       oa.push({Id: 4,
        OpcionId: 14,
        nombre: "Sin Azúcar",
        precio: 0,
        seccionId: 4}) 
    } */
        data.forEach((s) => {
          if (s.SeccionId == cat.Id) {
            ot.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio,
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            });
            allOpciones.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio,
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            })
          }
        });
        console.log("allOpciones 5",allOpciones);             
        setOpcionesSeleccionadas(ot);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSecciones()
    getSeccionesProducto()
  }, [])


  return(
    <Grid  item xs={12} md={6} lg={6}>
    <Grid container xs={10} justify="space-around">
        <Grid xs={4}>
        <h3>{cat.Nombre}</h3>
        </Grid>
        <Grid xs={4}>
         <IconButton
            aria-label="delete"
            style={{
              backgroundColor: "#AC7A47",
              color: "white",
            }}
            onClick={() => setForm(!form)}
          >
            {form?
             <RemoveCircle fontSize="small"/>
            :
            <AddCircleIcon fontSize="small" />
            }
          </IconButton>
          </Grid>
      </Grid>   
   {form? 
   <List style={{marginLeft:50}}>        
    <FormControlLabel
       control={<Checkbox  name="checkedS" onChange={()=>SelectAll() } checked={checkAll} color="#AC7A47"/>}
       label="Selecionar todos"
      />
        {opciones.map((value, i) => {
          const labelId = `checkbox-list-label-${value.OpcionId}`;

          return (
            <ListItem
              key={i}
              role={undefined}
              dense
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    opcionesSeleccionadas.findIndex((c) => c.OpcionId == value.OpcionId) == -1
                      ? false
                      : true
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.Nombre}`} />
            </ListItem>
          );
        })}
      </List>
      :null
      }
      </Grid>

  );
}

//opciones para agregar o quitar opciones

const Opciones2=({cat,product})=>{
  const classes = useStyles();
  const [form, setForm] = useState(false);
  const [tam, setTam] = useState("");
  const [opciones, setopciones] = useState(false);
  const [opcion, setopcion] = useState({
    nombre: "",
    precio: 0,
    seccionId: 0,
    OpcionId: 0,
    tipo: 0,
  });
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState(false);

  const getSecciones = async () => {
    Axios.get(`${GET_SECCIONES}`)
      .then((res) => {
        console.log(res.data);
        let data = res.data;
        let ot = [];
        data.forEach((s) => {
          if (s.Id == cat.Id) {
            ot.push(s);
          } 
        });     
        setopciones(ot);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeccionesProducto = async () => {
    Axios.get(`${GET_SECCIONES_PRODUCT}${product.Codigo}`)
      .then((res) => {
        console.log("secciones del producto ",res.data);
        console.log("allOpciones 7",allOpciones);
        let data = res.data;
        let ot = [];
        /* if(!product){
       oa.push({Id: 4,
        OpcionId: 14,
        nombre: "Sin Azúcar",
        precio: 0,
        seccionId: 4}) 
    } */
        data.forEach((s) => {
          if (s.SeccionId == cat.Id) {
            ot.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio.toFixed(2),
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            });            
            allOpciones.push({
              Id: s.Id,
              nombre: s.Nombre,
              precio: s.Precio.toFixed(2),
              productoId: s.ProductoId,
              seccionId: s.SeccionId,
              OpcionId:s.OpcionId
            }) 
          }
        });        
        setOpcionesSeleccionadas(ot);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSecciones()
    getSeccionesProducto()
  }, [])

  return(
    <Grid item xs={12} md={6} lg={6}>
          <Paper className={form ? classes.formL : classes.paper}>
            {form && (
              <div>
                <InputLabel id="demo-simple-select-label">
                  {cat.Nombre}
                </InputLabel>
                <Select
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    borderRadius: "7px",
                    height: "55px",
                  }}
                  color="#AC7A47"
                  options={opciones}
                  labelField="Nombre"
                  valueField="OpcionId"
                  onChange={(value) => {
                    let data = {
                      nombre: value[0].Nombre,
                      seccionId: value[0].Id,
                      OpcionId: value[0].OpcionId,
                      tipo: value[0].Tipo,
                    };
                    setopcion({ ...opcion, ...data });
                  }}
                  values={[opcion.nombre]}
                />
              </div>
            )}

            

            {form &&
              opciones.length > 0 &&
              opcion && (
                <div style={{ marginTop: "11px" }}>
                  <TextField
                    id="standard-basic"
                    label="precio"
                    variant="outlined"
                    value={opcion.Precio}                    
                    name="precio"
                    type="number"
                    onChange={(e) => {
                      let data = {
                        precio: parseFloat(e.target.value).toFixed(2),
                      };
                      console.log("opcion ",opcion);
                      setopcion({ ...opcion, ...data });
                    }}
                  />
                </div>
              )}

            {form && (
              <Button
                variant="contained"
                style={{ backgroundColor: "#AC7A47", color: "white" }}
                onClick={() => {
                  console.log("opcion ",opcion);
                  console.log("opcionesSeleccionadas ",opcionesSeleccionadas);
                  const found = opcionesSeleccionadas.find(
                    (o) => o.OpcionId == opcion.OpcionId
                  );
                  console.log("found ",found);
                  if (!found) {
                    setOpcionesSeleccionadas([
                      ...opcionesSeleccionadas,
                      opcion,
                    ]);
                    allOpciones.push(opcion)
                    setForm(false);
                    setTam("");
                  } else {
                    alert("ya tienes este tamaño");
                  }
                }}
              >
                Guardar
              </Button>
            )}

            {form && (
              <Button variant="contained" onClick={() => setForm(false)}>
                Cancelar
              </Button>
            )}

            {!form && (
              <h3>{cat.Nombre}</h3>
            )}

            {!form && (
              <IconButton
                aria-label="delete"
                style={{
                  backgroundColor: "#AC7A47",
                  color: "white",
                }}
                onClick={() => opciones.length!==0? setForm(true) :null}
              >
                <AddCircleIcon fontSize="small" />                               
                
              </IconButton>
            )}

            {!form && opcionesSeleccionadas.length > 0 && (
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell align="right">precio</TableCell>
                      <TableCell align="right">Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {opcionesSeleccionadas.map((row) => (
                      <TableRow key={row.nombre}>
                        <TableCell component="th" scope="row">
                          {row.nombre}
                        </TableCell>
                        <TableCell align="right">{row.precio}</TableCell>
                        <TableCell align="right" style={{ cursor: "pointer" }}>
                          <DeleteIcon
                            onClick={() => {
                              let op = opcionesSeleccionadas;
                              console.log("opcionesSeleccionadas ",op);
                              console.log("row ",row.Id);
                              allOpciones = allOpciones.filter(
                                (o) => o.Id !== row.Id
                              );                              
                              op = op.filter(
                                (o) => o.Id !== row.Id
                              );
                              console.log("opciones delete ",allOpciones);                              
                              setOpcionesSeleccionadas(op);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>  

  )

}
