export const formatPrice = (price) => {
  return new Intl.NumberFormat("en", {
    numberingSystem: "latn",
    style: "decimal",
    currency: "COP",
  }).format(price);
};


export const decimalTwo = (price) => {
    return Math.round(price * 100) / 100
}