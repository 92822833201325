import React, { useState, useEffect } from "react";
import {
  TableContainer,
  FormControl,
  InputLabel,
  Typography,
  LinearProgress,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AdminContext from "../../../context/admin/AdminContext";
import toast from "toasted-notes";
import { Axios } from "../../../config/axios";
import {
  UPDATE_STORE,
  UPLOAD_FILE_STORE,
  LOGIN,
  GET_STORE,
  GET_STORES,
} from "../../../constants";

import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
  typography: {
    color: "#707070",
    paddingLeft: "30px",
  },
  image: {
    width: "100%",
    height: "350px",
    border: "solid 1px grey",
  },
  icon: {
    padding: "40px 40px",
    alignContent: "center",
    textAlign: "center",
  },
  imageIcon: {
    width: "50%",
    height: "180px",
    border: "solid 1px grey",
  },
  label: {
    padding: "10px 10px",
    borderRadius: "20px",
    width: "100%",
    backgroundColor: "#AC7A47",
    color: "white",
    margin: "2rem",
  },
  input: {
    width: "60%",
    borderRadius: "10px",
  },
  contentINput: {
    margin: "2rem",
  },
}));

export const Banners = () => {
  const classes = useStyles();
  const [selectedFileB, setSelectedFileB] = useState();
  const [selectedFileI, setSelectedFileI] = useState();
  const [previewB, setPreviewB] = useState();
  const [previewI, setPreviewI] = useState();
  const [nombre, setNombre] = useState();
  const [err, setErr] = useState(false);
  const [stores, setStores] = useState([]);
  const [tiendas, setTiendas] = useState([]);
  const [checked, setChecked] = React.useState([]);
  const { admin, login } = React.useContext(AdminContext);
  const [checkeds, setCheckeds] = React.useState(false);
  const [activateCheckbox, setActivateCheckbox] = useState(false)
  const [codigoTienda, setCodigoTienda] = useState()
  const [nombreTienda, setnombreTienda] = useState()

  useEffect(() => {
    // handleAdmin();
    getStores();
    getTiendas();
  }, []);

  const handleAdmin = () => {
    console.log(admin);
    if (admin) setNombre(admin.nombre);
  };

  const onSelectFile = (e, x) => {
    if (x == 1) {
      setPreviewB(URL.createObjectURL(e.target.files[0]));
      setSelectedFileB(e.target.files[0]);
    } else {
      setPreviewI(URL.createObjectURL(e.target.files[0]));
      setSelectedFileI(e.target.files[0]);
    }
  };

  const getStores = async () => {
    Axios.get(GET_STORES)
      .then((res) => {
        let c = [];
        res.data.forEach((s) => {
          c.push({
            Codigo: s.Codigo,
          });
        });
        setChecked(c);
        setStores(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    console.log(activateCheckbox);
    setErr(false)
      if(nombre == ''){
          setErr(true)
          return
    }
    //en caso de seleccionar para todas las tiendas 
    if(activateCheckbox == true ){
      
      if(selectedFileB || selectedFileI){
        if(selectedFileI!==null){
          const fd = new FormData();
          fd.append("image", selectedFileI);                  
          Axios.post(
            `${UPLOAD_FILE_STORE}?Codigo=1&Nombre=${nombre}&tipo=icono&all=si`,
            fd
          )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if(selectedFileB!==null){
          const fd = new FormData();
          fd.append("image", selectedFileB);
          Axios.post(
            `${UPLOAD_FILE_STORE}?Codigo=1&Nombre=${nombre}&tipo=banner&all=si`,
            fd
          )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        
          toast.notify("Tienda Actualizada Correctamente");
          //getStore()
      }
      //en caso de seleccionar una tienda especifica
    }else{
      if(selectedFileB || selectedFileI){
        if(selectedFileI!==null){
          const fd = new FormData();
          fd.append("image", selectedFileI);                  
          Axios.post(
            `${UPLOAD_FILE_STORE}?Codigo=${codigoTienda}&Nombre=${nombreTienda}&tipo=icono&all=no`,
            fd
          )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if(selectedFileB!==null){
          const fd = new FormData();
          fd.append("image", selectedFileB);
          Axios.post(
            `${UPLOAD_FILE_STORE}?Codigo=${codigoTienda}&Nombre=${nombreTienda}&tipo=banner&all=no`,
            fd
          )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        
          toast.notify("Tienda Actualizada Correctamente");
          //getStore()
      }
    }
  }

  const getTiendas = async () => {
    let tienda = []
    Axios.get(GET_STORES)
      .then((res) => {
        let data = res.data
        data.map((data)=>{
          tienda.push({
            value: data.Codigo,
            label : data.Nombre
          })
        })
        setTiendas(tienda);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectTienda = (data) => {
    setCodigoTienda(data.value)
    setnombreTienda(data.label);
  }

  const handleChange = () => {
    setCheckeds(!checkeds);
    if(!checkeds == true){
      setActivateCheckbox(true)
    }else{
      setActivateCheckbox(false)
    }
  }

  return (
    <div>
      <div>
        <div style={{ paddingBottom: "20px" }}>
          
          <Typography
            component="h2"
            variant="h6"
            className={classes.typography}
            noWrap
          >
            Selección de tienda
          </Typography>

          <div style={{paddingLeft: "30px", marginBottom: "5px"}}>
          <label style={{color: "#707070"}}>
          <input type="checkbox" style={{width:"15px", height:"15px"}} checked={checkeds} onChange={handleChange}></input>
          Todas las tiendas
          </label>
          </div>

          <div style={{
                borderRadius: "7px",
                paddingLeft: "30px",
                height: "50px",
                width: 350,
              }}>
          <Select
              color="#707070"
              options={tiendas}
              labelField="Descripcion"
              valueField="Codigo"
              isDisabled={activateCheckbox}
              onChange={(result) => selectTienda(result)}
            />
          </div>

          <Typography
            component="h2"
            variant="h6"
            className={classes.typography}
            noWrap
          >
            Banner de la tienda
          </Typography>
          
        </div>
        <div style={{ width: "100%" }}>
          <input
            type="file"
            onChange={(e) => onSelectFile(e, 1)}
            style={{ display: "none" }}
            id="fileBanner"
          />
          <label for="fileBanner" color="primary" className={classes.label}>
            Buscar
          </label>
        </div>
        <div style={{ padding: "40px 40px" }}>
          {previewB ? <img className={classes.image} src={previewB} /> : null}
        </div>
      </div>

      <div>
        <div style={{ paddingBottom: "20px" }}>
          <Typography
            component="h2"
            className={classes.typography}
            variant="h6"
            noWrap
          >
            Icono de la tienda
          </Typography>
        </div>
        <div style={{ width: "100%" }}>
          <input
            type="file"
            onChange={(e) => onSelectFile(e, 0)}
            style={{ display: "none" }}
            id="fileIcon"
          />
          <label for="fileIcon" color="primary" className={classes.label}>
            Buscar
          </label>
        </div>
        <div className={classes.icon}>
          {previewI ? (
            <img className={classes.imageIcon} src={previewI} />
          ) : null}
        </div>
      </div>

      <div style={{display:"flex",justifyContent:"center",width:"100%"}} >
      <Button
        type="submit"
        fullWidth
        variant="contained"
        style={{ backgroundColor: "#AC7A47", color: "white",width:"50%" }}
        onClick={handleSubmit}
      >
        Guardar Cambios
      </Button>
      </div>
    </div>
  );
};
