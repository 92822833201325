export const buildDetail = (details) => {
  let build = [];
  details.forEach((detail) => {
    build.push({
      IdDetalle: detail.IdDetalle,
      CantidadDespachada: detail.Cantidad,
      CantidadMedioDespachada: 0.0,
    });
  });
  return build;
};
