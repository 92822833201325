import React, { useEffect, useState } from "react";
import AdminContext from "../../../context/admin/AdminContext";
import { Axios } from "../../../config/axios";
import { GET_REPORTS_DAY, GET_DATA_ORDERS_DAY } from "../../../constants";
import { Container, Grid, Paper, makeStyles } from "@material-ui/core";
import Fact from "./Fact";
import moment from "moment";
import { getChartsDay } from "../../../utils/charts";
import ChartsDay from "./ChartsDay";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "180px",
  },
  paperChart: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 300,
  },
}));

export const Reports = () => {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [numberOrders, setNumberOrders] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [hours, setHours] = useState([]);

  useEffect(() => {
    getOrdersDay();
  }, []);

  const getOrdersDay = async () => {
    Axios.get(`${GET_REPORTS_DAY}${admin.codigo}`)
      .then((res) => {
        console.log("ordenes",res)
        console.log(moment(res.data[0].Fecha).format("h"));
        console.log(getChartsDay(res.data));
        setHours(getChartsDay(res.data));
        setNumberOrders(res.data.length);
        Axios.get(`${GET_DATA_ORDERS_DAY}${admin.codigo}`)
          .then((res) => {
            console.log("detalleprdenes",res.data);
            setTotalSales(res.data.totalSales);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Fact
              value={totalSales}
              text="Venta total día"
              color="#64E986"
              isNumber={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Fact
              value={numberOrders}
              text="Pedidos Día"
              color="#FFB84F"
              isNumber={true}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper}>
            <Fact
              value={totalSales > 0 && totalSales / numberOrders}
              text="Promedio Compra"
              color="#85BCE1"
              isNumber={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperChart}>
            <ChartsDay hours={hours} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
