import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  LinearProgress,
  TextField,
  Button,
  InputLabel,
} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import {Select as Select2} from "react-dropdown-select";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Axios } from "../../../config/axios";
import { CREATE_SCHEDULE_STORE, GET_SCHEDULE_STORE, GET_STORES, UPDATE_SCHEDULE_STORE, DELETE_SCHEDULE_STORE } from "../../../constants";
import AdminContext from "../../../context/admin/AdminContext";
import toast from "toasted-notes";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/Delete";




//Imports select tienda
import Box from '@material-ui/core/Box';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { ro } from "date-fns/locale";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
    margin: "1rem",
  },
  form: {
    margin: "2rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%",
    backgroundColor: "#AC7A47",
    color: "white",
    borderRadius: "7px",
  },
  select: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  hour: {
    width: "100%",
  },
}));

export const Schedule = () => {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [schedule, setSchedule] = useState([]);
  const [day, setDay] = useState(
    {
      label: "Lunes",
      sg: "Lunes",
      value: 2
    });
  const [hourStart, setHourStart] = useState(new Date());
  const [hourEnd, setHourEnd] = useState(new Date());
  const [err, setErr] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(null);
  const [stores, setStores] = useState([]);
  const [age, setAge] = React.useState(1001);
  const [loading, setLoading] = React.useState(true);
  const [codTienda, setCodTienda] = useState(1001)


  

  const [days, setDays] = useState([
    {
      label: "Lunes",
      sg: "Lunes",
      value: 2,
    },
    {
      label: "Martes",
      sg: "Martes",
      value: 3,
    },
    {
      label: "Miercoles",
      sg: "Miercoles",
      value: 4,
    },
    {
      label: "Jueves",
      sg: "Jueves",
      value: 5,
    },
    {
      label: "Viernes",
      sg: "Viernes",
      value: 6,
    },
    {
      label: "Sabado",
      sg: "Sabado",
      value: 7,
    },
    {
      label: "Domingo",
      sg: "Domingo",
      value: 1,
    },
  ]);
  const handleChange = (event) => {
    event.preventDefault();
    setCodTienda(event.target.value)
    setAge(event.target.value);
  };
  const getSchedules = async () => {
       Axios.get(GET_SCHEDULE_STORE+age)
         .then((res) => {
           setLoading(false)
           setSchedule(res.data);
         })
         .catch((err) => {
           console.log([err]);
         });
     };
  const getstores = async () => {
    try { 
        const response = await Axios.get(GET_STORES)
        //setLoading(false);
         setStores(response.data);

        // response.data.forEach( (CodUsuario) => {
        //   try {
        //     console.log(CodUsuario.Nombre);
            
        //     setCodigo(CodUsuario);
           
        //   } catch (err) {
        //     console.log(err);
        //   }
        // })
      }
     
      catch(err)  {
        //setLoading(false);
        console.log(err);
      };
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    setErr(false);
    if (hourStart != "" && hourEnd != "" && day != "") {
      edit ? updateHorario() : createHorario()
    } else {
      setErr(true);
    }
  };

  const createHorario = () => {
    const currentIndex = schedule.findIndex((c) => c.Dw == day.value);
    if(currentIndex!==-1){
      toast.notify("Ya hay un horario asignado a este dia de la semana");
      return
    }
    let data = {
      codTienda: codTienda,
      dia: day.sg,
      dw: day.value,
      horaInicial: moment(hourStart).format("h:mm a").toUpperCase(),
      horaFinal: moment(hourEnd).format("h:mm a").toUpperCase(),
    };
    console.log(data)
    let url = CREATE_SCHEDULE_STORE;
    Axios.post(url, data)
      .then((res) => {
        getSchedules();
        toast.notify("Horario Creado Correctamente");
      })
      .catch((err) => {
        toast.notify("Hubo un error al crear el horario: " + err);
      });
  };

  const updateHorario = () => {
    console.log("day ", schedule);
    console.log("Item" , item.CodTienda);
    var aux=schedule.filter((c) => c.Codigo !== item.Codigo);
    console.log("aux ", aux);
    const currentIndex = aux.findIndex((c) => c.Dw == day.value);
    if(currentIndex!==-1){
      toast.notify("Ya hay un horario asignado a este dia de la semana");
      // return
    }
    let data = {
      codTienda: item.CodTienda,
      dia: day.sg,
      dw: day.value,
      horaInicial: moment(hourStart).format("h:mm a").toUpperCase(),
      horaFinal: moment(hourEnd).format("h:mm a").toUpperCase(),
    };
    console.log(item);
    let url = UPDATE_SCHEDULE_STORE;
    Axios.post(url + item.Codigo, data)
      .then((res) => {
        getSchedules();
        toast.notify("Horario Actualizado Correctamente");
        console.log(data);
      })
      .catch((err) => {
        toast.notify("Hubo un error al crear el horario: " + err);
      });
  };

  const convertDate = (row) => {
    var HoraFinalFormat = row.HoraFinal.split(" ");
    var HoraInicialFormat = row.HoraInicial.split(" ");
    var HoraInicial2 = HoraInicialFormat[0].split(":");
    var HoraFinal2 = HoraFinalFormat[0].split(":");
    if (HoraInicialFormat[1] === "PM") {
      HoraInicial2[0] = parseInt(HoraInicial2[0]) + 12;
    }
    if (HoraFinalFormat[1] === "PM") {
      HoraFinal2[0] = parseInt(HoraFinal2[0]) + 12;
    }
    if (HoraFinal2[0] === "12") {
      HoraFinal2[0] = parseInt(HoraFinal2[0]) + 12;
    }
    if (HoraInicial2[0] === "12") {
      HoraInicial2[0] = parseInt(HoraInicial2[0]) + 12;
    }
    setHourStart(new Date(`Fri Jan 20 2012 ${HoraInicial2[0]}:${HoraInicial2[1]}:00 GMT-0500`).toUTCString())
    setHourEnd(new Date(`Fri Jan 20 2012 ${HoraFinal2[0]}:${HoraFinal2[1]}:00 GMT-0500`).toUTCString())
  }

  const DeleteCategory = (value) => {
    console.log("item para eliminar",value);
    let url = DELETE_SCHEDULE_STORE;
    Axios.delete(url + value)
    .then((res)=>{
      toast.notify("Horario Eliminado Correctamente");
      setTimeout(() => {
        window.location.reload()
      }, 1500);
      
    })
    .catch((err)=>{
      toast.notify("hubo un error al eliminar el horario")
    })
  }
  
 
  useEffect(() => {
    getSchedules();
    getstores();
  }, [age]);

  if (loading)
    return (
      <>
        <LinearProgress color="primary" style={{ margin: "1rem" }} />
      </>
    );
  
  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
         



 
    <Box sx={{ minWidth: 120 }}>
    <Box sx={{ minWidth: 120 }}>
    <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label2">Seleccione Tienda</InputLabel>
    
        <Select
          labelId="demo-simple-select-label2"
          id="demo-simple-select2"
          value={age}
          label="Tienda"
          onChange={handleChange}
          
        >
      { stores.map((row ,i ) => (
          <MenuItem key={i}   value={row.Codigo}>{row.Nombre} - {row.Codigo}</MenuItem>
          ))  }
        </Select>
         
      </FormControl>

    </Box>
    </Box>
  

            <Paper className={classes.paper}>
              
              <TableContainer style={{ maxHeight: 470 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 50 }}>Codigo Tienda</TableCell>
                      <TableCell style={{ minWidth: 60 }}>Día</TableCell>
                      <TableCell style={{ minWidth: 80 }}>Hora apertura{" "}</TableCell>
                      <TableCell style={{ minWidth: 70 }}>Hora cierre{" "}</TableCell>
                      <TableCell style={{ maxWidth: 70 }}>Editar </TableCell>
                      <TableCell style={{ maxWidth: 70 }}>Eliminar </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schedule.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell>{row.CodTienda}</TableCell>
                        <TableCell
                          style={{ color: "#2e1156", cursor: "pointer" }}
                          component="th"
                          scope="row"
                        >
                          {row.Dia}
                        </TableCell>
                        <TableCell>{row.HoraInicial}</TableCell>
                        <TableCell>{row.HoraFinal}</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          <EditOutlinedIcon
                            style={{}}
                            onClick={() => {
                              setEdit(true);
                              setItem(row);
                              convertDate(row);                              
                            }}
                          />
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          <DeleteIcon
                            onClick={() => {
                              DeleteCategory(row.Codigo)
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper className={classes.paper}>
              <Typography
                component="h2"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                {edit ? "Actualizar Horario" : "Nuevo Horario"}
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <InputLabel id="demo-simple-select-label">Día</InputLabel>
                <Select2
                  style={{
                    marginTop: "1rem",
                    borderRadius: "7px",
                    height: "55px",
                  }}
                  color="primary"
                  options={days}
                  labelField="label"
                  valueField="value"
                  className={classes.select}
                  onChange={(value) => (setDay(value[0]))}
                  values={[
                    item != null
                      ? days.find((d) => d.value == item.Dw)
                      : days[0],
                  ]}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Hora de apertura"
                      onChange={(date) => setHourStart(date)}
                      value={hourStart}
                      className={classes.hour}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Hora de cierre"
                      onChange={(date) =>{setHourEnd(date);  }}
                      value={hourEnd}
                      className={classes.hour}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                >
                  {edit ? "Actualizar Horario" : "Registrar Horario"}
                </Button>
                {edit && (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={() => setEdit(false)}
                  >
                    Cancelar
                  </Button>
                )}
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};