import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  
  Container,
  Typography,
} from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import clsx from "clsx";
import Title from "../Dashboard/Title";
import { Axios } from "../../../config/axios";
import { GET_CUSTOMERS_STORE } from "../../../constants";
import AdminContext from "../../../context/admin/AdminContext";
import { getChartsCustomers } from "../../../utils/charts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 400,
  },
  fixedHeightMin: {
    height: 170,
  },
}));

export const Customers = () => {
  const { admin } = React.useContext(AdminContext);
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [charts,setCharts] = useState([]);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixed = clsx(classes.paper, classes.fixedHeightMin);

  const getCustomers = () => {
    Axios.get(`${GET_CUSTOMERS_STORE}${admin.codigo}`)
      .then((res) => {
        setCustomers(res.data)
        setCharts(getChartsCustomers(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer style={{ maxHeight: 470 }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 70 }}>Nombres</TableCell>
                  <TableCell style={{ minWidth: 140 }}>Apellidos</TableCell>
                  <TableCell style={{ minWidth: 140 }}>Compras </TableCell>                  
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      style={{ color: "#2e1156", cursor: "pointer" }}
                      component="th"
                      scope="row"
                    >
                      {row.Nombres}
                    </TableCell>
                    <TableCell>{row.Apellidos}</TableCell>
                    <TableCell>
                      {" "}
                      {"$ " +
                        new Intl.NumberFormat("en", {
                          numberingSystem: "latn",
                          style: "decimal",
                          currency: "COP",
                        }).format(row.Compras)}
                    </TableCell>                   
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper} elevation={3}>
            {/* <Title>Clientes que más compran </Title> */}
            <ResponsiveContainer>
              <BarChart
                width={800}
                height={200}
                data={charts}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="compras" fill="#7fe7cc" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
