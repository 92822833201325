import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../components/Auth/Login";
import Dashboard from "../components/Admin/Dashboard/Dashboard";
import { AdminRoute, AuthRoute } from "./PrivateRoute";
import AdminContext from "../context/admin/AdminContext";
import SuperDashboard from "../components/SuperAdmin/Dashboard/SuperDashboard";
import tarjetaCredito from '../components/payment/tarjetaCredito';
import PaymentResponse from '../components/payment/PaymentResponse'

export const AppRoute = () => {
  const { admin } = useContext(AdminContext);

  return (
    <Router>
      <div>
        <Switch>
          <AuthRoute
            exact
            path="/"
            isAuthenticated={admin ? true : false}
            user={admin}
            component={Login}
          />
          <AdminRoute
            exact
            path="/super/dashboard"
            isAuthenticated={admin ? true : false}
            component={SuperDashboard}
          />
          <AdminRoute
            exact
            path="/dashboard"
            isAuthenticated={admin ? true : false}
            component={Dashboard}
          />
          <AdminRoute
            exact
            path="/tarjeta"
            isAuthenticated={admin ? false : true}
            component={tarjetaCredito}
          />
          <AdminRoute 
          exact
          path="/respuesta/pago" 
          isAuthenticated={admin ? false : true}
          component={PaymentResponse} />
        </Switch>
      </div>
    </Router>
  );
};
