import React, { useEffect } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Title from "../Dashboard/Title";
import moment from "moment";

import axios from "axios";
import AdminContext from "../../../context/admin/AdminContext";
import {
  TableContainer,
  FormControl,
  InputLabel,
  MenuItem,
  Slide,
  Typography,
  LinearProgress,
  Grid,
  Button,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { headerGet } from "../../../utils/headers";
import {
  URL_BASE,
  GET_STORES,
  GET_PRODUCTS_STORE,
  UPDATE_PRODUCT_STATE,
  GET_CATEGORIES_STORE,
  GET_PRODUCTS_STORE_CATEGORY,
  UPDATE_PRODUCT_STATE_STORE,
} from "../../../constants";
import { Axios } from "../../../config/axios";
import { ProductForm } from "./ProductForm";
import Switch from "@material-ui/core/Switch";
import toast from "toasted-notes";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Select from "react-dropdown-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr",
    columnGap: "10px",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const filter = createFilterOptions();

export default function Products() {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [backup, setBackup] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [form, setForm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [cat, setCat] = React.useState("");

  const [value, setValue] = React.useState(null);

  useEffect(() => {
    getProducts();
    getCategories();
    if (!form) {
      setProduct("");
    }
  }, [form]);

  const getProducts = async () => {
    console.log(admin.codigo);
    Axios.get(`${GET_PRODUCTS_STORE}${admin.codigo}`)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        setProducts(res.data);
        setBackup(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCategories = async () => {
    Axios.get(`${GET_CATEGORIES_STORE}${admin.codigo}/${1}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          const data = res.data;
          data.unshift({
            Codigo: -1,
            Descripcion: "TODOS",
          });
          setCategories(data);

          //setCat(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (row) => {
    console.log(row);
    console.log(admin);
    setOpen(true);
    setMsg(row.Agotado == 0 ? "Inactivar" : "Activar");
    setProduct(row);
  };
  const handleState = () => {
    console.log(!product.Agotado);
    Axios.post(UPDATE_PRODUCT_STATE_STORE, {
      codTienda: admin.codigo,
      codProducto: product.Codigo,
      Estado: product.Estado == 1 ? 0 : 1,
    })
      .then((res) => {
        console.log(res);
        getProducts();
        toast.notify(
          `Producto ${
            product.Agotado ? "Activado" : "Inactivado"
          } correctamente`
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    getProducts();
  };

  const handleCategory = async (item) => {
    setCat(item);
    if (item.Codigo == -1) {
      getProducts();
    } else {
      Axios.get(`${GET_PRODUCTS_STORE_CATEGORY}${admin.codigo}/${item.Codigo}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            setProducts(res.data);
          } else {
            setProducts([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (loading)
    return (
      <>
        <LinearProgress color="primary" style={{ margin: "1rem" }} />
      </>
    );

  return (
    <React.Fragment>
      <div className={classes.header}>
        {!form && (
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              console.log(newValue);
              if (typeof newValue === "string") {
                setValue({
                  Nombre: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue({
                  title: newValue.inputValue,
                });
              } else {
                if (newValue !== null) {
                  setProducts(
                    backup.filter((p) => p.Codigo == newValue.Codigo)
                  );
                } else {
                  setProducts(backup);
                }
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={products}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.Nombre;
            }}
            renderOption={(option) => option.Nombre}
            style={{ width: 250 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar producto"
                variant="outlined"
              />
            )}
          />
        )}
        {!form && (
          <div>
            {/* <InputLabel id="demo-simple-select-label">Filtrar por Ca</InputLabel> */}

            <Select
              style={{
                borderRadius: "7px",
                height: "55px",
                width: 200,
              }}
              color="#707070"
              options={categories}
              labelField="Descripcion"
              valueField="Codigo"
              onChange={(value) => handleCategory(value[0])}
              values={[
                cat
                  ? cat
                  : {
                      Descripcion: "Filtrar por categoria",
                    },
              ]}
            />
          </div>
        )}
      </div>

      {form ? (
        <ProductForm setForm={setForm} product={product} />
      ) : (
        <TableContainer style={{ minHeight: 470 }}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Codigo</TableCell>
                <TableCell style={{ minWidth: 180 }}>Nombre</TableCell>
                <TableCell style={{ minWidth: 140 }}>Precio</TableCell>
                <TableCell style={{ minWidth: 140 }}>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    style={{ color: "#2e1156", cursor: "pointer" }}
                    component="th"
                    scope="row"
                  >
                    {row.Codigo}
                  </TableCell>
                  <TableCell>{row.Nombre}</TableCell>
                  <TableCell>
                    {" "}
                    {"$ " +
                      new Intl.NumberFormat("en", {
                        numberingSystem: "latn",
                        style: "decimal",
                        currency: "COP",
                      }).format(row.Precio)}
                  </TableCell>
                  <TableCell>
                    <Switch
                      //defaultChecked={row.Agotado == 0 ? true : false}
                      checked={row.Estado ? true : false}
                      onChange={() => handleChange(row)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Vas a {msg} un producto
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Estas seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleState} color="primary">
            Si
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
