import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StorefrontIcon from "@material-ui/icons/Storefront";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import {
  ExpandLess,
  ExpandMore,
  MoveToInbox as InboxIcon,
  StarBorder,
  ExitToApp,
} from "@material-ui/icons";
import { makeStyles, List, Collapse } from "@material-ui/core";
import AdminContext from "../../../context/admin/AdminContext";
import logo from "../../../assets/img/cel.png";
import FastfoodOutlinedIcon from "@material-ui/icons/FastfoodOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  colorText: {
    color: "#707070",
  },
}));

export const MainListItems = ({ handleRoute }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <StorefrontIcon />
        </ListItemIcon>
        <ListItemText primary="Tiendas" className={classes.colorText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => handleRoute(1)}
          >
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary="Tiendas" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => handleRoute(2)}
          >
            <ListItemIcon>
              <CategoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Categorías" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => handleRoute(3)}
          >
            <ListItemIcon>
              <FastfoodOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Productos" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => handleRoute(4)}
          >
            <ListItemIcon>
              <PermMediaIcon />
            </ListItemIcon>
            <ListItemText primary="Banners" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => handleRoute(5)}
          >
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
            <ListItemText primary="Horario" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => handleRoute(6)}
          >
            <ListItemIcon>
              <PeopleAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        </List>
      </Collapse>
      {/* <ListItem button onClick={() => handleRoute(5)}>
        <ListItemIcon>
          <CategoryOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Categorías" className={classes.colorText} />
      </ListItem> */}
    </div>
  );
};

export const SecondaryListItems = ({ open }) => {
  console.log(open);
  const { logout } = useContext(AdminContext);

  return (
    <div>
      <ListItem
        button
        onClick={() => {
          logout();
        }}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </ListItem>
      {open && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "90px",
          }}
        >
          <img src={logo} width="90" height="18" style={{ margin: "1em" }} />
        </div>
      )}
    </div>
  );
};