import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  TextField,
  Button,
  Switch,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Axios } from "../../../config/axios";
import {
  CREATE_CATEGORY_STORE,
  GET_CATEGORIES_STORE,
  UPDATE_CATEGORY_STORE_STATE,
  UPDATE_CATEGORY_STORE,
} from "../../../constants";
import AdminContext from "../../../context/admin/AdminContext";
import toast from "toasted-notes";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
    margin: "1rem",
  },
  form: {
    margin: "2rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "55%",
  },
}));

export const Categories = () => {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [categories, setCategories] = useState([]);
  const [cat, setCat] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [err, setErr] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [edit, setEdit] = React.useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    Axios.get(`${GET_CATEGORIES_STORE}${admin.codigo}/${1}`)
      .then((res) => {
        console.log(res.data);
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (row) => {
    console.log(row);
    setOpen(true);
    setMsg(row.Estado == 1 ? "Inactivar" : "Activar");
    setCat(row);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErr(false);
    console.log(descripcion);
    if (descripcion != "") {
      {
        edit ? updateCategory() : createCategory();
      }
    } else {
      setErr(true);
    }
  };

  const createCategory = () => {
    Axios.post(CREATE_CATEGORY_STORE, {
      descripcion,
      codTienda: admin.codigo,
    })
      .then((res) => {
        setDescripcion("");
        getCategories();
        toast.notify("Categoría Creada Correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.notify("Hubo un error al crear la categoría");
      });
  };

  const updateCategory = () => {
    Axios.post(UPDATE_CATEGORY_STORE + cat.Codigo, {
      descripcion,
      codTienda: admin.codigo,
    })
      .then((res) => {
        setDescripcion("");
        getCategories();
        toast.notify("Categoría Actualizada Correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.notify("Hubo un error al actualizar la categoría");
      });
  };

  const handleState = () => {
    console.log(admin.codigo)
    Axios.post(UPDATE_CATEGORY_STORE_STATE, {
      CodTienda: admin.codigo,
      CodCategoria: cat.Codigo,
      Estado: cat.Estado == 1 ? 0 : 1,
    })
      .then((res) => {
        console.log(res);
        getCategories();
        toast.notify(
          `Categoria ${
            cat.Estado == 0 ? "Activada" : "Inactivada"
          } correctamente`
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Paper className={classes.paper}>
              <TableContainer style={{ maxHeight: 470 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 70 }}>Codigo</TableCell>
                      <TableCell style={{ minWidth: 140 }}>
                        Descripción{" "}
                      </TableCell>
                      <TableCell style={{ minWidth: 140 }}>Estado </TableCell>
                      {/* <TableCell style={{ minWidth: 100 }}>Editar</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categories.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          style={{ color: "#2e1156", cursor: "pointer" }}
                          component="th"
                          scope="row"
                        >
                          {row.Codigo}
                        </TableCell>
                        <TableCell>{row.Descripcion}</TableCell>
                        <TableCell>
                          <Switch
                            checked={row.Estado == 1 ? true : false}
                            onChange={() => handleChange(row)}
                            color="primary"
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </TableCell>
                        {/* <TableCell style={{ cursor: "pointer" }}>
                          <EditOutlinedIcon
                            style={{ margin: "1rem" }}
                            onClick={() => {
                              setCat(row);
                              setEdit(true);
                              setDescripcion(row.Descripcion);
                            }}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={5}>
            <Paper className={classes.paper}>
              <Typography
                component="h2"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                {edit ? "Actualizar Categoría" : "Nueva Categoría"}
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <TextField
                  id="standard-basic"
                  label="Descripción"
                  name="descripción"
                  variant="outlined"
                  onChange={(e) => setDescripcion(e.target.value.toUpperCase())}
                  value={descripcion}
                  error={err}
                  helperText={err && "Campo obligatorio"}
                />

                <div
                  style={{
                    margin: "1rem",
                    display: "grid",
                    gridTemplateColumns: edit ? "1fr 1fr" : "1fr",
                    columnGap: edit ? "10px" : "0px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#AC7A47",
                      color: "white",
                      borderRadius: "7px",
                    }}
                  >
                    {edit ? "Actualizar" : "Registrar"}
                  </Button>
                  {edit && (
                    <Button
                      onClick={() => {
                        setEdit(false);
                        setDescripcion("");
                      }}
                      type="button"
                      variant="contained"
                      style={{
                        backgroundColor: "#707070",
                        color: "white",
                        borderRadius: "7px",
                      }}
                    >
                      Cancelar
                    </Button>
                  )}
                </div>
              </form>
            </Paper>
          </Grid> */}
        </Grid>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Vas a {msg} una Categoría
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Estas seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleState} color="primary">
            Si
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
