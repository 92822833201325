import React, { useState, useEffect } from 'react';
import axios from "axios";
import {URLPAIMENT, SECRETKEY} from '../../constants/index'



const hex = require('string-hex')

const TarjetaCredito = () => {
    let restUrl = URLPAIMENT
    let cclw = SECRETKEY

    const procesarDatos = (valor, itbms, idCliente,codCompra) => {
        let idclien = `Compras Duran Coffee Store ref: ${idCliente}`
        let nombre_pago = "pascual_pago"
        var infoPago = hex(`[{"id":${codCompra},"nameOrLabel":${nombre_pago},"value":${codCompra}}]`)
        var codigoCompra = hex(codCompra)
        var urlRespuesta = hex("https://pascual-piack-and-go.web.app/respuesta/pago")
        var sendData = `CMTN=${valor}&CTAX=${itbms}&CDSC=${idclien}&PARM_1=${codigoCompra}&CCLW=${cclw}&PF_CF=${infoPago}&RETURN_URL=${urlRespuesta}`
        
        setTimeout(() => {
            axios.post(restUrl,sendData)
            .then((res) => {
            let response = res.data.data.url
                window.location.href = response
            })
            .catch((err) => {
            console.log(err);
            });
        }, 500);
    }
    
    useEffect(() => {
        let URLactual = (new URL(document.location)).searchParams;
        let valor = URLactual.get("valor") //valor de la compra
        let itbms = URLactual.get("itbms") // itbms o impuesto
        let codCompra = URLactual.get("codCompra") // codigo de la compra
        let idCliente = URLactual.get("idcliente") // id del cliente
        
        procesarDatos(valor, itbms, idCliente, codCompra)
        //url = http://localhost:3000/tarjeta?valor=50&itbms=1.0&idcliente=1053790872&codCompra=b2b123456"
       
    },[])
 
    return (
      <React.Fragment>  
      </React.Fragment>
    )
    
  }

export default TarjetaCredito;
