import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StorefrontIcon from "@material-ui/icons/Storefront";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import AdminContext from "../../../../context/admin/AdminContext";
import {
  TableContainer,
  FormControl,
  InputLabel,
  MenuItem,
  Slide,
  Typography,
  LinearProgress,
  Grid,
  Button,
  FormControlLabel,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  UPDATE_PRODUCT_STATE,
  GET_CATEGORIES_STORE,
  GET_PRODUCTS_STORE_CATEGORY,
  GET_CATEGORIES,
  GET_PRODUCTS,
  DELETE_PRODUCT,
  GET_STORES,
  STORES_PRODUCT,
  PRODUCT_STORE,
  UPLOAD_FILE_PRODUCT,
  IMAGE_URL,
  GET_PRODUCTS_CATEGORY,
  UPDATE_ORDEN_PRODUCTS,
} from "../../../../constants";
import { Axios } from "../../../../config/axios";
import { ProductForm } from "./ProductForm";
import Switch from "@material-ui/core/Switch";
import toast from "toasted-notes";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Select from "react-dropdown-select";
import { ProductFormComplex } from "./ProductFormComplex";
import { DropzoneDialog } from "material-ui-dropzone";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  list: {
    margin: 0,
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr",
    columnGap: "10px",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const filter = createFilterOptions();

export default function ProductsStore() {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [backup, setBackup] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const [form, setForm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [categoriesA, setCategoriesA] = React.useState([]);
  const [complex, setComplex] = React.useState(false);
  const [cat, setCat] = React.useState("");
  const [stores, setStores] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [open3, setOpen3] = React.useState(false);

  const [value, setValue] = React.useState(null);
  const [codCat, setCodCat] = React.useState(null);

  const [openImage, setOpenImage] = React.useState(false);


  useEffect(() => {
    getProducts();
    getCategories();
    getStores();
    if (!form) {
      setProduct("");
    }
  }, [form]);

  const getStores = async () => {
    Axios.get(GET_STORES)
      .then((res) => {
        
        setStores(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStores = (row) => {
    setProduct(row);
    Axios.get(STORES_PRODUCT + row.Codigo)
      .then((res) => {
        const data = res.data;
        if (data.length > 0) {
          let c = [];
          data.forEach((s) => {
            c.push({
              Codigo: s.CodTienda,
            });
          });
          if(c.length===stores.length){
            setCheckAll(true);
          }
          setChecked(c);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handle = () => {
    Axios.post(`${PRODUCT_STORE}`, {
      codProducto: product.Codigo,
      tiendas: checked.map((c) => c.Codigo),
    })
      .then((res) => {
        toast.notify("Tiendas Asignadas Correctamente");
        setOpen3(false);
      })
      .catch((err) => {
        toast.notify("Hubo un error al asignar las tiendas");
        setOpen3(false);
      });
  };

  const getProducts = async () => {
    Axios.get(`${GET_PRODUCTS}`)
      .then((res) => {
        console.log(res);
        localStorage.setItem("countProducts", res.data.length)
        setLoading(false);
        setProducts(res.data);
        setBackup(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getCategories = async () => {
    Axios.get(`${GET_CATEGORIES}?type=${0}`)
      .then((res) => {
        if (res.data.length > 0) {
          const data = res.data;
          data.unshift({
            Codigo: -1,
            Descripcion: "TODOS",
          });
          setCategories(data);

          //setCat(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (row) => {
    setOpen(true);
    setMsg(row.Agotado == 0 ? "Inactivar" : "Activar");
    setProduct(row);
  };
  const handleState = () => {
    Axios.post(UPDATE_PRODUCT_STATE, {
      CodTienda: -1,
      codProducto: product.Codigo,
      estado: product.Agotado ? 0 : 1,
    })
      .then((res) => {
        getProducts();
        toast.notify(
          `Producto ${
            product.Agotado ? "Activado" : "Inactivado"
          } correctamente`
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setProduct("")
    getProducts();
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex((c) => c.Codigo == value.Codigo);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push({
        Codigo: value.Codigo,
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const SelectAll=()=>{
    const newChecked = [...checked];
    stores.map((store)=>{
      const currentIndex = newChecked.findIndex((c) => c.Codigo === store.Codigo);      
      if (currentIndex === -1) {
        if(!checkAll){
          newChecked.push({
            Codigo: store.Codigo,
          });
        }
      } else {
        if(checkAll){
          newChecked.splice(currentIndex, 1);
        }
      }      
    })
    setChecked(newChecked);
    setCheckAll(!checkAll)
  }

  const DeleteProduct=(id)=>{
    Axios.delete(`${DELETE_PRODUCT}?id=${id}`)
      .then((res) => {
        getProducts();
        toast.notify("Producto Eliminada Correctamente");
      })
      .catch((err) => {
        toast.notify("Hubo un error al eliminar el Producto");
      });
  }

  const handleCategory = async (item) => {
    setCat(item);
    if (item.Codigo == -1) {
      getProducts();
    } else {
      Axios.get(`${GET_PRODUCTS_CATEGORY}${item.Codigo}`)
        .then((res) => {
          if (res.data.length > 0) {
            setProducts(res.data);
          } else {
            setProducts([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const newProduct = () => {
    let catsA = [];
    categories.forEach((c) => {
      if (c.Estado == 1 && c.Codigo != -1) {
        catsA.push(c);
      }
    });
    setCategoriesA(catsA);
    setOpen2(true);
    //setForm(!form);
  };

  const editImage = (files) => {
    const fd = new FormData();
    fd.append("image", files[0]);
    Axios.post(
      `${UPLOAD_FILE_PRODUCT}?CodTienda=${7006}&Codigo=${product.Codigo}`,
      fd
    )
      .then((res) => {
        if (res.data.Estado == "Error") {
          toast.notify("No se pudo guardar la imagen");
        } else {                    
          setTimeout(() => {
            toast.notify("Imagen guardada Correctamente");
            getProducts();
          setForm(false);
          setOpenImage(false)
          window.location.reload(); 
          }, 500);         
        }
      })
      .catch((err) => {
        console.log(err);
        toast.notify("No se pudo guardar la imagen");
      });
  };

  const changeOrden = (orden,data) => {
    if(orden > 0){
      console.log("orden",orden, "data",data);
      Axios.post(UPDATE_ORDEN_PRODUCTS + "codigo=" + data + "&Orden=" + orden)
      .then((res)=>{
        console.log(res);
      }).catch((err)=>{
        console.log(err);
      })
    }else{
      console.log("no es posible el cambio");
    }
 
  }

  if (loading)
    return (
      <>
        <LinearProgress color="primary" style={{ margin: "1rem" }} />
      </>
    );

  return (
    <React.Fragment>
      <div className={classes.header}>
        {!form && (
          <Button
            variant="contained"
            color="primary"
            endIcon={<AddCircleIcon fontSize="large" />}
            onClick={newProduct}
            style={{
              backgroundColor: "#AC7A47",
              color: "white",
              margin: "1rem",
              width: "50%",
            }}
          >
            Nuevo Producto
          </Button>
        )}

        {form && (
          <Button
            variant="contained"
            color="primary"
            endIcon={<StorefrontIcon />}
            onClick={() =>{ 
              setForm(!form) 
              setComplex(false)}}
            style={{
              backgroundColor: "#AC7A47",
              color: "white",
              margin: "1rem",
              width: "50%",
            }}
          >
            Ver productos
          </Button>
        )}

        {!form && (
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  Nombre: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue({
                  title: newValue.inputValue,
                });
              } else {
                if (newValue !== null) {
                  setProducts(
                    backup.filter((p) => p.Codigo == newValue.Codigo)
                  );
                } else {
                  setProducts(backup);
                }
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={products}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.Nombre;
            }}
            renderOption={(option) => option.Nombre}
            style={{ width: 250 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar producto"
                variant="outlined"
              />
            )}
          />
        )}
        {!form && (
          <div>
            {/* <InputLabel id="demo-simple-select-label">Filtrar por Ca</InputLabel> */}

            <Select
              style={{
                borderRadius: "7px",
                height: "55px",
                width: 200,
              }}
              color="#707070"
              options={categories}
              labelField="Descripcion"
              valueField="Codigo"
              onChange={(value) => handleCategory(value[0])}
              values={[
                cat
                  ? cat
                  : {
                      Descripcion: "Filtrar por categoria",
                    },
              ]}
            />
          </div>
        )}
      </div>

      {form ? (
        <>
          {complex ? (
            <ProductFormComplex
              setForm={setForm}
              product={product}
              setComplex={setComplex}
              codCat={codCat}
            />
          ) : (
            <ProductForm setForm={setForm} product={product} codCat={codCat} />
          )}
        </>
      ) : (
        <TableContainer style={{ maxHeight: 500, minHeight: 400 }}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Codigo</TableCell>
                <TableCell style={{ minWidth: 180 }}>Nombre</TableCell>
                <TableCell style={{ minWidth: 140 }}>Categoria</TableCell>
                <TableCell style={{ minWidth: 140 }}>Precio</TableCell>
                <TableCell style={{ minWidth: 140 }}>Estado</TableCell>
                <TableCell style={{ minWidth: 140 }}>Editar</TableCell>
                <TableCell style={{ minWidth: 140 }}>Orden</TableCell>
                <TableCell style={{ minWidth: 140 }}>Editar Imagen</TableCell>
                <TableCell style={{ minWidth: 140 }}>Imagen</TableCell>
                <TableCell style={{ minWidth: 140 }}>Tiendas</TableCell>
                
                <TableCell>Eliminar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    style={{ color: "#2e1156" }}
                    component="th"
                    scope="row"
                  >
                    {row.Codigo}
                  </TableCell>
                  <TableCell>{row.Nombre}</TableCell>
                  <TableCell>{row.nombreCat}</TableCell>
                  <TableCell>
                    $ {row.Precio.toFixed(2)}                    
                  </TableCell>
                  <TableCell>
                    <Switch
                      //defaultChecked={row.Agotado == 0 ? true : false}
                      checked={row.Agotado ? false : true}
                      onChange={() => handleChange(row)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                  <TableCell style={{ cursor: "pointer" }}>
                    <EditOutlinedIcon
                      style={{ margin: "1rem" }}
                      onClick={() => {
                        if(row.TipoCat===1){
                          setComplex(true)                         
                        }
                        setProduct(row);
                        setForm(true);
                      }}
                    />
                  </TableCell>
                  
                  {/* orden de productos */}
                  <TableCell style={{ color: "#2e1156" }} component="th" scope="row">
                      <input
                      type="number"
                      style={{width:"50px", textAlign:"lefth"}}
                      onChange={e=>changeOrden(e.target.value,row.Codigo)}
                      defaultValue={row.Orden}
                      /> 
                  </TableCell>
                  {/* fin orden de productos */}

                  <TableCell style={{ cursor: "pointer",textAlign:"center" }}>
                    <ImageSearchIcon
                      style={{ margin: "1rem" }}
                      onClick={() => {
                        setProduct(row);
                        setOpenImage(true);
                      }}
                    />
                  </TableCell>
                  <TableCell style={{textAlign:"center" }}>
                    {row.Img?
                    <img style={{width:100,height:100}} src={row.Img}></img>
                    :
                    <NotInterestedIcon
                      style={{ margin: "1rem",cursor: "pointer",fontSize:40 }}
                      onClick={() => {
                        setProduct(row);
                        setOpenImage(true);
                      }}
                    />
                    }
                    
                  </TableCell>
                  <TableCell style={{ cursor: "pointer" }}>
                    <StoreOutlinedIcon
                      onClick={() => {
                        setOpen3(true);
                        handleStores(row);
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" style={{ cursor: "pointer" }}>
                          <DeleteIcon
                            onClick={() => {
                              DeleteProduct(row.Codigo)
                            }}
                          />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Selecciona la categoría del producto nuevo
        </DialogTitle>
        <DialogContent dividers style={{ height: "300px" }}>
          <Select
            style={{
              borderRadius: "7px",
              height: "55px",
              width: 200,
            }}
            color="#707070"
            options={categoriesA}
            labelField="Descripcion"
            valueField="Codigo"
            onChange={(value) => {
              setCodCat(value[0].Codigo);
              if (value[0].Tipo == 1) {
                setComplex(true);
              } else {
                setComplex(false);
              }
              setForm(!form);
              setOpen2(false);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen2(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Selecciona las tiendas
        </DialogTitle>
        <DialogContent dividers style={{ height: "300px" }}>
          <List className={classes.list}>
          <FormControlLabel
           control={<Checkbox  name="checkedA" onChange={()=>SelectAll() } checked={checkAll} color="primary"/>}
           label="Selecionar todos"
          />
            {stores.map((value, i) => {
              const labelId = `checkbox-list-label-${value.Codigo}`;

              return (
                <ListItem
                  key={i}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        checked.findIndex((c) => c.Codigo == value.Codigo) == -1
                          ? false
                          : true
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.Nombre}`} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>         
          <Button onClick={() => setOpen3(false)} color="primary">
            Cerrar
          </Button>
          <Button onClick={() => handle()} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Vas a {msg} un producto
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Estas seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleState} color="primary">
            Si
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        initialFiles={[product.Img]}
        dialogTitle="Editar Imagen"
        dropzoneText="Arrastre y suelte un archivo aquí o haga clic"
        submitButtonText="Guardar"
        open={openImage}
        onSave={editImage}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={() => {
          setOpenImage(false);
          setProduct("")
        }}
      />
    </React.Fragment>
  );
}
