import React from "react";
import { Route, Redirect } from "react-router-dom";

export const AdminRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export const AuthRoute = ({
  isAuthenticated,
  user,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <Redirect to={user.super ? "/super/dashboard" : "dashboard"} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
