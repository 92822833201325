import { LOGIN, LOGOUT, SAVE_NOTIFICATION } from "./types";

export default (state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        admin: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        admin: action.payload,
      };
    case SAVE_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};
