import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { MainListItems, SecondaryListItems } from "./listItems";
import {
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import UIfx from "uifx";
import soundMp3 from "../../../sounds/xperia.mp3";
import AdminContext from "../../../context/admin/AdminContext";
import toast from "toasted-notes";

import Title from "./Title";

import icon from "../../../assets/icon/favicon.ico";
import Shops from "../Shops/Shops";
import { ShopForm } from "../Shops/ShopForm";
import { Categories } from "../../SuperAdmin/Categories/Categories";
import CategoriesStore from "../Shops/Categories/CategoriesStore";
import ProductsStore from "../Shops/Products/ProductsStore";
import { Banners } from "../Banners/Banners";
import { Customers } from "../Customers/Customers";
import  {Schedule}  from "../Schedule/Schedule";

const sound = new UIfx(soundMp3);
let socket;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.celuweb.com/">
        CELUWEB.COM
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: "#fff",
    color: "#707070",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 300,
  },
  fixedHeightMin: {
    height: 180,
  },
  notify: {
    color: "#2e1156",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SuperDashboard() {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [open, setOpen] = React.useState(true);
  const [route, setRoute] = React.useState(1);

  const [count, setCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {}, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRoute = (route) => {
    setRoute(route);
  };

  const handleClick = (event) => {
    //setNotifications([])
    setCount(0);
    setAnchorEl(event.currentTarget);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixed = clsx(classes.paper, classes.fixedHeightMin);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h2"
            variant="h6"
            style={{ color: "#707070" }}
            noWrap
            className={classes.title}
          >
            {(route == 1 && "TIENDAS") ||
              (route == 2 && "CATEGORÍAS") ||
              (route == 3 && "PRODUCTOS") ||
              (route == 4 && "BANNERS") ||
              (route == 5 && "HORARIO") ||
              (route == 6 && "CLIENTES")}
          </Typography>

          {/* <img src={logo} width="100" height="30" style={{ margin: "1em" }} /> */}
          {/* <Typography
            component="h2"
            variant="h6"
            color="initial"
            noWrap
            style={{ margin: "0.5em" }}
          >
            {`NUEVA TIENDA`}
          </Typography>
          <IconButton
            size="medium"
            color="inherit"
            onClick={handleClick}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton> */}
          {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={
              !form ? <AddCircleIcon fontSize="large" /> : <StorefrontIcon />
            }
            onClick={() => setForm(!form)}
            style={{ backgroundColor: "#8900D8", color: "white" }}
          >
            {!form ? "Nueva Tienda" : "Ver tiendas"}
          </Button> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <h3 style={{ color: "#707070" }}>BACKOFFICE</h3>
          {/* <h4>Emart</h4> */}
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{<MainListItems handleRoute={handleRoute} />}</List>
        <Divider />
        <List>{<SecondaryListItems open={open} />}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {/* <Title>Ventas del Día</Title> */}
          <Grid container spacing={3}>
            {route == 1 && (
              <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>{<Shops />}</Paper>
                </Grid>
              </>
            )}
            {route == 2 && (
              <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>{<CategoriesStore />}</Paper>
                </Grid>
              </>
            )}
            {route == 3 && (
              <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>{<ProductsStore />}</Paper>
                </Grid>
              </>
            )}
            {route == 4 && (
              <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>{<Banners />}</Paper>
                </Grid>
              </>
            )}{route == 5 && (
              <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>{<Schedule />}</Paper>
                </Grid>
              </>
            )}
            {route == 6 && (
              <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>{<Customers />}</Paper>
                </Grid>
              </>
            )}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}