import React, { useState, useEffect } from "react";
import {
  TableContainer,
  FormControl,
  InputLabel,
  Typography,
  LinearProgress,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AdminContext from "../../../context/admin/AdminContext";
import toast from "toasted-notes";
import { Axios } from "../../../config/axios";
import { UPDATE_STORE, UPLOAD_FILE_STORE, LOGIN, GET_STORE } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  typography: {
    color: "#707070",
    paddingLeft: "30px",
  },
  image: {
    width: "100%",
    height: "350px",
    border: "solid 1px grey",
  },
  icon: {
    padding: "40px 40px",
    alignContent: "center",
    textAlign: "center",
  },
  imageIcon: {
    width: "50%",
    height: "180px",
    border: "solid 1px grey",
  },
  label: {
    padding: "10px 10px",
    borderRadius: "20px",
    width: "100%",
    backgroundColor: "#AC7A47",
    color: "white",
    margin: "2rem",
  },
  input: {
    width: "60%",
    borderRadius: "10px",
  },
  contentINput: {
    margin: "2rem",
  },
}));

export const Edit = () => {
  const classes = useStyles();
  const [selectedFileB, setSelectedFileB] = useState();
  const [selectedFileI, setSelectedFileI] = useState();
  const [previewB, setPreviewB] = useState();
  const [previewI, setPreviewI] = useState();
  const [nombre, setNombre] = useState();
  const [err, setErr] = useState(false);
  const { admin, login } = React.useContext(AdminContext);

  useEffect(() => {
    handleAdmin();
    setPreviewI(admin.urlIcon)
    setPreviewB(admin.urlBanner)
    console.log("url ",admin.urlBanner);
    console.log("icon ",admin.urlIcon);
  }, []);

  const handleAdmin = () => {
    if (admin) setNombre(admin.nombre);
  };

  const onSelectFile = (e, x) => {
    if (x == 1) {
      setPreviewB(URL.createObjectURL(e.target.files[0]));
      setSelectedFileB(e.target.files[0]);
    } else {
      setPreviewI(URL.createObjectURL(e.target.files[0]));
      setSelectedFileI(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    setErr(false)
    if(nombre == ''){
        setErr(true)
        return
    }
    if(selectedFileB || selectedFileI){
      console.log(nombre)
      console.log(selectedFileB)
      console.log(selectedFileI)
      if(selectedFileI!==null){
        const fd = new FormData();
        fd.append("image", selectedFileI);                  
        Axios.post(
          `${UPLOAD_FILE_STORE}?Codigo=${admin.codigo}&Nombre=${nombre}&tipo=icono&all=no`,
          fd
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
       }
       if(selectedFileB!==null){
        const fd = new FormData();
        fd.append("image", selectedFileB);
        Axios.post(
          `${UPLOAD_FILE_STORE}?Codigo=${admin.codigo}&Nombre=${nombre}&tipo=banner&all=no`,
          fd
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
       }
       
        toast.notify("Tienda Actualizada Correctamente");
        //getStore()
    }
    
}

  const getStore = () => {
    Axios.get(`${GET_STORE}${admin.codigo}`)
      .then((res) => {
        console.log(res.data);
        login(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div>
        <Typography
          component="h2"
          variant="h6"
          className={classes.typography}
          noWrap
        >
          Titulo
        </Typography>
        <div className={classes.contentINput}>
          <TextField
            className={classes.input}
            id="nombre"
            label="Nombre"
            name="nombre"
            variant="outlined"
            onChange={(e) => setNombre(e.target.value)}
            value={nombre}
            error={err}
            helperText={err && "Campo obligatorio"}
          />
        </div>
      </div>
      <div>
        <div style={{ paddingBottom: "20px" }}>
          <Typography
            component="h2"
            variant="h6"
            className={classes.typography}
            noWrap
          >
            Banner de la tienda
          </Typography>
        </div>
        <div style={{ width: "100%" }}>
          <input
            type="file"
            onChange={(e) => onSelectFile(e, 1)}
            style={{ display: "none" }}
            id="fileBanner"
          />
          <label for="fileBanner" color="primary" className={classes.label}>
            Buscar
          </label>
        </div>
        <div style={{ padding: "40px 40px" }}>
          {previewB ? <img className={classes.image} src={previewB} /> : null}
        </div>
      </div>

      <div>
        <div style={{ paddingBottom: "20px" }}>
          <Typography
            component="h2"
            className={classes.typography}
            variant="h6"
            noWrap
          >
            Icono de la tienda
          </Typography>
        </div>
        <div style={{ width: "100%" }}>
          <input
            type="file"
            onChange={(e) => onSelectFile(e, 0)}
            style={{ display: "none" }}
            id="fileIcon"
          />
          <label for="fileIcon" color="primary" className={classes.label}>
            Buscar
          </label>
        </div>
        <div className={classes.icon}>
          {previewI ? (
            <img className={classes.imageIcon} src={previewI} />
          ) : null}
        </div>
      </div>
      <div style={{display:"flex",justifyContent:"center",width:"100%"}} >
      <Button
        type="submit"
        fullWidth
        variant="contained"
        style={{ backgroundColor: "#AC7A47", color: "white",width:"50%" }}
        onClick={handleSubmit}
      >
        Guardar Cambios
      </Button>
      </div>
    </div>
  );
};
