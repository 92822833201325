import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { useFormik } from "formik";
import * as Yup from "yup";

import { URL_BASE, LOGIN } from "../../constants";
import AdminContext from "../../context/admin/AdminContext";
import toast from "toasted-notes";
import LoadingBar from "react-top-loading-bar";
import logo from "../../assets/img/logo.png";
import { Axios } from "../../config/axios";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://celuweb.com/">
        Celuweb
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/img/fondo.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    //backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(5, 5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login({ history }) {
  const classes = useStyles();

  const { login } = React.useContext(AdminContext);
  const [add, setAdd] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      usuario: "",
      clave: "",
    },
    validationSchema: Yup.object({
      usuario: Yup.string().required("El usuario es requerido"),
      clave: Yup.string().required("La clave es requeridad"),
    }),
    onSubmit: (values) => {
      Axios
        .post(LOGIN, values, {
          onUploadProgress: (progressEvent) => {
            setAdd(
              parseInt((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        })
        .then((res) => {
          console.log(res.data);
          login(res.data);
          toast.notify(`Bienvenido, ${res.data.usuario}`);
          // history.push("/dashboard");
          // toast.notify(`Bienvenido, ${res.data.pdv.nombre}`);
        })
        .catch((err) => {
          console.log(err);
          toast.notify("Datos incorrectos");
          setAdd(0);
        });
    },
  });

  const onLoaderFinished = () => {
    setAdd(0);
  };

  return (
    <>
      <LoadingBar
        progress={add}
        height={3}
        color="#AC7A47"
        onLoaderFinished={onLoaderFinished}
      />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "70%", height: "200px" }}
            />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="user"
                label="Usuario *"
                name="usuario"
                type="text"
                autoFocus
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.usuario}
                // helperText={formik.touched.user && formik.errors.user}
                // error={formik.touched.user && formik.errors.user ? true : false}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="clave"
                label="Clave *"
                type="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.clave}
                // helperText={formik.touched.password && formik.errors.password}
                // error={formik.touched.password && formik.errors.password ? true : false}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#AC7A47", color: "white" }}
                className={classes.submit}
              >
                Iniciar Sesión
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
