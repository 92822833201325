import moment from "moment";

export const getChartsDay = (orders) => {
  let hours = [];
  for (let i = 1; i < 24; i++) {
      const hour = {
          hour: i+":00",
          value: i,
          sale: 0
      }
      hours.push(hour)
  }
  orders.forEach(order => {
       hours.forEach(hour => {
            if(hour.value == moment(order.Fecha).format("h")){
                hour.sale = order.Valor
            }
       });
  });
  return hours
};

export const getChartsCustomers = (customers) => {
    
    let charts = [];
    for (let i = 0; i < customers.length; i++) {
      let totalCompras = customers[i].Compras
      let total = totalCompras.toFixed(2);
      // let total = 
      console.log(total);
      const chart = {
        name: customers[i].Nombres,
        compras: total,
      };
      charts.push(chart);
    }
    return charts;
  };
