import React from "react";
import { AppRoute } from "./routers/AppRoute";
import 'react-widgets/dist/css/react-widgets.css';
import "toasted-notes/src/styles.css";

 
function App() {
  return <AppRoute />;
}

export default App;
