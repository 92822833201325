import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AdminState from '../src/context/admin/AdminState'
import './index.css'

ReactDOM.render(
  <AdminState>
    <App />
  </AdminState>,

  document.getElementById("root")
);
