import React, { useEffect } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Title from "../Dashboard/Title";
import moment from "moment";
import toast from "toasted-notes";

import axios from "axios";
import AdminContext from "../../../context/admin/AdminContext";
import {
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slide,
  Typography,
  LinearProgress,
  Grid,
  Button,
  Switch,
  DialogContentText,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { headerGet } from "../../../utils/headers";
import { URL_BASE,DELETE_STORE, GET_STORES, UPDATE_STORE_STATE } from "../../../constants";
import { ShopForm } from "./ShopForm";
import { Axios } from "../../../config/axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Shops() {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [stores, setStores] = React.useState([]);
  const [store, setStore] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [form, setForm] = React.useState(false);
  const [type, setType] = React.useState('');
  const [shop, setShop] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  useEffect(() => {
    getStores();
  }, [form]);

  const getStores = async () => {
    Axios.get(GET_STORES)
      .then((res) => {
        setLoading(false);
        setStores(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (row) => {
    console.log(row);
    setOpen(true);
    setMsg("")
    setMsg(row.Estado == 1 ? "Inactivar" : "Activar");
    setStore(row);
  };

  const handleState = () => {
    Axios.post(UPDATE_STORE_STATE, {
      codigo: store.Codigo,
      estado: store.Estado == 1 ? 0 : 1,
    })
      .then((res) => {
        console.log(res);
        getStores();
        toast.notify(
          `Tienda ${
            store.Estado == 0 ? "Activada" : "Inactivada"
          } correctamente`
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };

  const DeleteStore=(id,Nombre,Clave)=>{
    Axios.delete(`${DELETE_STORE}?id=${id}&Nombre=${Nombre}&Clave=${Clave}`)
      .then((res) => {
        console.log(res);
        getStores();
        toast.notify("Tienda Eliminada Correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.notify("Hubo un error al eliminar la Tienda");
      });
  }

 

  

  if (loading)
    return (
      <>
        <LinearProgress color="primary" style={{ margin: "1rem" }} />
      </>
    );

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        endIcon={
          !form ? <AddCircleIcon fontSize="large" /> : <StorefrontIcon />
        }
        onClick={() =>{  setType("crear");
         setForm(!form)}}
        style={{
          backgroundColor: "#AC7A47",
          color: "white",
          margin: "1rem",
          width: "20%",
        }}
      >
        {!form ? "Nueva Tienda" : "Ver tiendas"}
      </Button>
      {form ? (
        <ShopForm setForm={setForm} type={type} shop={shop} />
      ) : (
        <TableContainer style={{ maxHeight: 470 }}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Codigo</TableCell>
                <TableCell style={{ minWidth: 180 }}>Nombre</TableCell>
                <TableCell style={{ minWidth: 140 }}>Representante</TableCell>
                <TableCell style={{ minWidth: 180 }}>Razón Social</TableCell>
                <TableCell style={{ minWidth: 180 }}>Usuario</TableCell>
                <TableCell style={{ minWidth: 180 }}>Clave</TableCell>
                <TableCell style={{ minWidth: 140 }}>Dirección</TableCell>
                <TableCell>Télefono</TableCell>
                <TableCell>Editar</TableCell>
                <TableCell>Eliminar</TableCell>
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stores.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    style={{ color: "#2e1156" }}
                    component="th"
                    scope="row"
                  >
                    {row.Codigo}                  
                  </TableCell>
                  <TableCell>{row.Nombre}</TableCell>
                  <TableCell>{row.Representante}</TableCell>
                  <TableCell>{row.RazonSocial}</TableCell>
                  <TableCell>{row.Usuario}</TableCell>
                  <TableCell>{row.Clave}</TableCell>
                  <TableCell>{row.Direccion}</TableCell>
                  <TableCell>{row.Telefono}</TableCell>
                  <TableCell>
                    <Button
                    onClick={() =>{  
                    setShop(row)  
                    setType("editar");
                    setForm(!form)}}
                    >
                      <EditIcon fontSize="large" style={{color:"#edbf42"}} />
                    </Button>
                  </TableCell>
                  <TableCell align="center" style={{ cursor: "pointer" }}>
                          <DeleteIcon
                            onClick={() => {
                              DeleteStore(row.Codigo,row.Nombre,row.Clave)
                            }}
                          />
                        </TableCell>
                  <TableCell>
                    <Switch
                      checked={row.Estado == 1 ? true : false}
                      onChange={() => handleChange(row)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {msg} Tienda
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Estas seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleState} color="primary">
            Si
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
