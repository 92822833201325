import React, { useState, useEffect, useRef } from "react";
import CryptoJ from "crypto-js";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Collapse,
  Box,
  Typography,
  TableBody,
  Dialog,
  Slide,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  GET_ORDERS_STORE,
  GET_ORDERS_DETAILS,
  GET_ENCRIPT,
  GET_OPTIONS_DETAILS,
  CHANGE_ENTREGADO,
  GET_STATES,
  GET_ORDERS_PENDING,
  GET_MOTIVOS_RECHAZO,
  INSERT_MOTIVO_RECHAZO,
} from "../../../constants";
import AdminContext from "../../../context/admin/AdminContext";
import { Axios } from "../../../config/axios";
import { useReactToPrint } from "react-to-print";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Print from "./Print";
import { calcularTotal } from "../../../utils/total";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import Select from "react-dropdown-select";
import toast from "toasted-notes";
import { buildDetail } from "../../../utils/orders";
import DialogContentText from "@material-ui/core/DialogContentText";

import { TrendingUpRounded } from "@material-ui/icons";
var Opciones = [];

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const Orders = ({ count }) => {
  const { admin } = React.useContext(AdminContext);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [tranSac, settranSac] = React.useState(true);
  const [details, setDetails] = useState([]);
  const [Rechazar, setRechazar] = useState({});
  const [pedido, setPedido] = useState("");
  const [open2, setOpen2] = React.useState(false);
  const [total, setTotal] = useState(0);
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [OptionsPDF, setOptionsPDF] = useState([]);
  const [motivosRechazo, setMotivosRechazo] = useState([]);
  const [ActivateMotivosRechazo, setActivateMotivosRechazo] = useState(false);
  const [IdMotivosRechazo, setIdMotivosRechazo] = useState(0);
  const [numeroPedido, setNumeroPedido] = useState();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Pedido - " + pedido.NumeroPedido,
  });

  useEffect(() => {
    getOrders();
    getMotivosRechazo();
  }, [count, OptionsPDF]);

  const getStates = async (Estado) => {
    Axios.get(`${GET_STATES}${Estado}`)
      .then((res) => {
        //setLoading(false);
        /* var data=res.data;
        const currentIndex = data.findIndex((c) => c.Codigo == 2);
        data.splice(currentIndex,1)
        var orden=[data[0],data[3],data[1],data[4],data[2]]
        setStates(orden); */
        setStates(res.data);
        setState(res.data.find((s) => s.Codigo == Estado));
      })
      .catch((err) => {
        //setLoading(false);
        console.log(err);
      });
  };

  const getOrders = async () => {
    Axios.get(`${GET_ORDERS_PENDING}${admin.codigo}`)
      .then((res) => {
        //setLoading(false);
        setOrders(res.data);
      })
      .catch((err) => {
        //setLoading(false);
        console.log(err);
      });
  };

  const UpdateOrders = () => {
    Axios.get(`${GET_ORDERS_PENDING}${admin.codigo}`)
      .then((res) => {
        const orders = res.data;
        orders.map((order) => {
          if (order.Estado === 0) {
            const DateOrder = moment(new Date(order.Fecha))
              .add(1, "hours")
              .format();
            const DateNow = Date.now();
            const minutes = moment(DateNow).diff(DateOrder, "minutes");
            if (2 < minutes) {
              var data = {
                CodTienda: admin.codigo,
                Data: ["" + order.Id],
                Web: true,
                NombreUser: order.Nombres + " " + order.Apellidos,
                Email: order.Correo,
                NumeroPedido: order.NumeroPedido,
              };
              let url = "Pedido/Rechazar";
              service(3, url, data, true);
            }
          }
        });
        //toast.notify("se Actualizaron los pedidos");
      })
      .catch((err) => {
        //setLoading(false);
        console.log(err);
      });
  };

  const getOpcionesPDF = async (detalles) => {
    var cont = 0;
    detalles.forEach((detalle) => {
      Axios.get(GET_OPTIONS_DETAILS + detalle.IdDetalle)
        .then((res) => {
          setTimeout(function () {
            cont++;
            if (res.data.length !== 0) {
              Opciones.push([
                {
                  detalle: detalle,
                  opciones: res.data,
                },
              ]);
            } else {
              Opciones.push([
                {
                  detalle: detalle,
                  opciones: [],
                },
              ]);
            }
            if (cont === detalles.length) {
              setOptionsPDF(Opciones);
              setOpen(true);
            }
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const getOrdersDetails = async (item) => {
    setNumeroPedido(item.NumeroPedido);
    Axios.get(`${GET_ORDERS_DETAILS}${item.NumeroPedido}`)
      .then(async (res) => {
        await getOpcionesPDF(res.data);
        getStates(item.Estado);
        //setLoading(false);
        setDetails(res.data);
        setPedido(item);
        setTotal(calcularTotal(res.data));
        //ordenarStates(item);
      })
      .catch((err) => {
        //setLoading(false);
        console.log(err);
      });
  };

  const ordenarStates = (item) => {
    const index = states.findIndex((s) => s.Codigo == item.Estado);
    setStates(states.slice(index, states.length));
  };

  const changeState = (item) => {
    let data;
    if (item.Codigo == 1) {
      let Detalle = buildDetail(details);
      data = {
        NumeroPedido: pedido.NumeroPedido,
        Valor: pedido.Valor,
        Observacion: pedido.Observacion,
        Detalle,
      };

      let url = "Pedido/Despachar";
      service(item.Codigo, url, data);
      return;
    }
    if (item.Codigo == 4 || item.Codigo == 3) {
      data = {
        CodTienda: admin.codigo,
        Data: ["" + pedido.Id],
        Web: true,
        NombreUser: pedido.Nombres + " " + pedido.Apellidos,
        Email: pedido.Correo,
        NumeroPedido: pedido.NumeroPedido,
      };
      let url = item.Codigo == 4 ? "Pedido/EnProceso" : "Pedido/Rechazar";
      service(item.Codigo, url, data, false);
      return;
    }
    if (item.Codigo == 5) {
      Axios.post(CHANGE_ENTREGADO + pedido.NumeroPedido)
        .then((res) => {
          getOrders();
          toast.notify("El pedido fue entregado");
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const EliminarTransaccion = (Ref) => {
    Axios.post(
      `${INSERT_MOTIVO_RECHAZO}motivo=${IdMotivosRechazo}&numeroPedido=${numeroPedido}`
    )
      .then((res) => {
        Axios.post(
          "https://emartwebapi.celuwebdev.com/PasarelaPagosCw/pascualpickandgo/Auth",
          {
            username: admin.usuario,
          }
        )
          .then((res) => {
            var token = res.data.token;
            var config = {
              headers: { Authorization: `Bearer ${token}` },
            };
            console.log(
              "url ",
              `https://emartwebapi.celuwebdev.com/PasarelaPagosCw/report/pascualpickandgo?_reference=${Ref}`
            );
            Axios.get(
              `https://emartwebapi.celuwebdev.com/PasarelaPagosCw/report/pascualpickandgo?_reference=${Ref}`,
              {
                username: admin.usuario,
              }
            )
              .then((res) => {
                var id_tran = res.data[0].id_transaction;
                var merchanid = "bc_58082903920";
                Axios.get(GET_ENCRIPT + merchanid)
                  .then((res) => {
                    var merchanID = res.data;
                    Axios.post(
                      "https://emartwebapi.celuwebdev.com/PasarelaPagosCw/pascualpickandgo/cancel/payment",
                      {
                        merchantID: merchanID,
                        transaction_id: id_tran,
                        description: "El pedido fue rechazado",
                      },
                      config
                    )
                      .then((res) => {
                        changeState(Rechazar);
                      })
                      .catch((err) => {
                        setOpen2(false);
                        toast.notify("Hubo un error al cancelar la trasaccion");
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    setOpen2(false);
                    toast.notify("Hubo un error al cancelar la trasaccion");
                    console.log(err);
                  });
              })
              .catch((err) => {
                setOpen2(false);
                toast.notify("Hubo un error al cancelar la trasaccion");
                console.log(err);
              });
          })
          .catch((err) => {
            setOpen2(false);
            toast.notify("Hubo un error al cancelar la trasaccion");
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const service = (code, url, data, all) => {
    Axios.post(url, data)
      .then((res) => {
        getOrders();
        if (code == 1) {
          toast.notify("El pedido fue despachado");
        } else if (code == 3) {
          Axios.post(
            `${INSERT_MOTIVO_RECHAZO}motivo=4&numeroPedido=${data.NumeroPedido}`
          )
            .then((res) => {
              console.log("pedido eliminado por inactividad");
            })
            .catch((err) => {
              console.log(err);
            });
          if (!all) {
            setOpen2(false);
            toast.notify("El pedido fue rechazado");
          }
        } else if (code == 4) {
          if (!all) {
            toast.notify("El pedido esta en proceso");
          }
        } else if (code == 5) {
          if (!all) {
            toast.notify("El pedido fue Entegado");
          }
        }
        if (!all) {
          getStates(code);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refuse = (data) => {
    Axios.post("Pedido/EnProceso", data)
      .then((res) => {
        getOrders();
        toast.notify("El pedido fue rechazado");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMotivosRechazo = () => {
    Axios.get(GET_MOTIVOS_RECHAZO)
      .then((res) => {
        setMotivosRechazo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    Opciones = [];
    setOptionsPDF([]);
    setOpen(false);
    setOpen2(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        maxWidth="xl"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "1rem",
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              style={{ color: "#707070", marginRight: "1rem" }}
              flexDirection="row"
            >
              Nombre Cliente:&nbsp; {pedido.nombres} &nbsp;&nbsp;
              Telefono:&nbsp; {pedido.telefono}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "1rem",
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              style={{ color: "#707070", marginRight: "1rem" }}
            >
              Detalle Pedido
            </Typography>
            <Button
              variant="contained"
              color="default"
              startIcon={<PictureAsPdfIcon />}
              onClick={handlePrint}
            >
              Generar Reporte
            </Button>
            <Typography
              component="h2"
              variant="h6"
              style={{
                color: "#707070",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            >
              Cambiar Estado
            </Typography>

            <Select
              style={{
                borderRadius: "7px",
                height: "50px",
              }}
              color="#707070"
              options={states}
              labelField="Descripcion"
              valueField="Codigo"
              onChange={(value) => {
                if (value[0].Codigo === "3") {
                  setRechazar(value[0]);
                  setActivateMotivosRechazo(true);
                } else {
                  changeState(value[0]);
                  setActivateMotivosRechazo(false);
                }
              }}
              values={[state]}
            />
            {/* seleccionar motivo de rechazo  */}
            <div>
              {ActivateMotivosRechazo == true ? (
                <Select
                  style={{
                    borderRadius: "7px",
                    height: "50px",
                    width: "400px",
                    marginLeft: "10px",
                  }}
                  color="#707070"
                  options={motivosRechazo}
                  labelField="Descripcion"
                  valueField="id"
                  onChange={(value) => {
                    setIdMotivosRechazo(value[0].id);
                    setOpen2(true);
                  }}
                  values={[motivosRechazo]}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{
              display: "block",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              style={{
                color: "#707070",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
              Wrap
            >
              Referencia De Pago:{pedido.RefPago}
            </Typography>
            <div style={{ display: "none" }}>
              <Print
                ref={componentRef}
                data={{
                  pedido,
                  OptionsPDF,
                  total,
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer style={{ maxHeight: 500, width: "100%" }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell style={{ minWidth: 180 }}>Nombre</TableCell>
                  <TableCell style={{ minWidth: 120 }}>Precio</TableCell>
                  <TableCell style={{ minWidth: 140, textAlign: "center" }}>
                    Cantidad
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>Sub Total</TableCell>
                  <TableCell style={{ minWidth: 400 }}>Observación</TableCell>
                  {/* <TableCell style={{ minWidth: 180 }}><i class="fas fa-tachometer-alt-slowest    "></i></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((row, i) => (
                  <Row key={i} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="center"
            style={{ margin: "1rem", minHeight: "100px" }}
          ></Grid>
        </DialogActions>
        <Dialog
          open={open2}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setOpen(false);
            setOpen2(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Vas a Rechazar un Pedido
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Estas seguro?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => EliminarTransaccion(pedido.RefPago)}
              color="primary"
            >
              Si
            </Button>
            <Button onClick={() => setOpen2(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <TableContainer style={{ maxHeight: 470 }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 170 }}>Acciones</TableCell>
              <TableCell>N° Pedido</TableCell>
              <TableCell>N° Orden</TableCell>
              <TableCell style={{ minWidth: 180 }}>Fecha</TableCell>
              <TableCell style={{ minWidth: 140 }}>Precio</TableCell>
              <TableCell style={{ minWidth: 180 }}>Observación</TableCell>
              <TableCell style={{ minWidth: 100 }}>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<UpdateOutlinedIcon />}
                    onClick={() => {
                      getOrdersDetails(row);
                    }}
                  >
                    Gestionar Pedido
                  </Button>
                </TableCell>
                <TableCell
                  style={{ color: "#2e1156", cursor: "pointer" }}
                  component="th"
                  scope="row"
                >
                  {row.NumeroPedido}
                </TableCell>
                <TableCell
                  style={{ color: "#2e1156", cursor: "pointer" }}
                  component="th"
                  scope="row"
                >
                  {row.Id}
                </TableCell>
                <TableCell>
                  {moment(row.Fecha).format("YYYY-MM-D, h:mm a")}
                </TableCell>
                <TableCell>$ {row.Valor.toFixed(2)}</TableCell>
                <TableCell>
                  {row.Observacion ? row.Observacion : "No hay observaciones"}
                </TableCell>
                <TableCell>{row.Descripcion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [Options, setOptions] = useState([]);
  const classes = useRowStyles();

  const getOpciones = async () => {
    Axios.get(GET_OPTIONS_DETAILS + row.IdDetalle)
      .then((res) => {
        setOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOpciones();
  }, []);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {Options.length !== 0 ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell>{row.Nombre}</TableCell>
        <TableCell>$ {row.Precio.toFixed(2)}</TableCell>
        <TableCell style={{ textAlign: "center" }}>{row.Cantidad}</TableCell>
        <TableCell>$ {(row.Precio * row.Cantidad).toFixed(2)}</TableCell>
        <TableCell>
          {row.Observacion ? row.Observacion : "No hay observaciones"}
        </TableCell>
      </TableRow>
      {Options.length !== 0 ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Opciones del producto
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Seccion</TableCell>
                      <TableCell>Opcion</TableCell>
                      <TableCell>Precio</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Options.map((detail, i) => (
                      <TableRow key={i}>
                        <TableCell>{detail.seccion}</TableCell>
                        <TableCell component="th" scope="row">
                          {detail.Nombre}
                        </TableCell>

                        <TableCell>
                          $ {Number.parseFloat(detail.precio).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}
