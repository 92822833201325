import React, { Component } from "react";
import moment from "moment";
import { Axios } from "../../../config/axios";
import {
  GET_ORDERS_STORE,
  GET_ORDERS_DETAILS,
  GET_OPTIONS_DETAILS,
  GET_STATES,
  GET_ORDERS_PENDING,
} from "../../../constants";

export default class Print extends Component {
  constructor(props) {
    super(props);
    this.state = { data: this.props.data };

    
  }


  componentWillMount() {

   /*  var Opciones=[]
    this.state.data.details.forEach(detalle => {
        Axios.get(GET_OPTIONS_DETAILS+detalle.IdDetalle)    
      .then((res) => {      
        if(res.data.length!==0){
          Opciones.push(res.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
      });
      console.log("estas son las opciones ",Opciones);
      this.setState({
        Opciones: Opciones
      }) */
      
  }
   


  render() {
    const { data } = this.state;
    const { OptionsPDF } = data;
    {console.log("OptionsPDF ",OptionsPDF)}

    if (false) {
      return <h1>Hola</h1>;
    }

    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            margin: "2rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1>
            {`Pedido - ${data.pedido.NumeroPedido}`} Fecha:{" "}
            <span>{moment().format("DD/MM/YYYY HH:mm a")}</span>{" "}
          </h1>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            margin: "2rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ margin: "0.5rem" }}>Detalles del Pedido </h2>

          {/* {data.pedido.observacion && (
            <div style={{ margin: "2rem" }}>
              <h2>Observacion General: </h2>
              <p style={{ fontSize: "20px" }}>{data.pedido.observacion}</p>
            </div>
          )} */}

          
              {OptionsPDF.map((row, index) => (
                <>
               
                <table>
            <thead>
              <th style={{ fontSize: 20 }}>Nombre</th>
              <th style={{ fontSize: 20 }}>Precio</th>
              <th style={{ fontSize: 20 }}>Cantidad</th>
              <th style={{ fontSize: 20 }}>Sub Total</th>
              <th style={{ fontSize: 20 }}>Observación</th>
            </thead>
            <tbody>
                <tr>
                  <td style={{ textAlign: "center",fontSize: 20 }}>{row[0].detalle.Nombre}</td>
                  <td style={{ fontSize: 20,textAlign: "center" }}>
                    $ {Number.parseFloat(row[0].detalle.Precio).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center",fontSize: 20 }}>{row[0].detalle.Cantidad}</td>
                  <td style={{ textAlign: "center",fontSize: 20 }}>
                    {/* {" "}
                    {"$ " +
                      new Intl.NumberFormat("en", {
                        numberingSystem: "latn",
                        style: "decimal",
                        currency: "COP",
                      }).format(row.Precio * row.Cantidad)} */}
                       $ {Number.parseFloat(row[0].detalle.Precio * row[0].detalle.Cantidad).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "center",fontSize: 20 }}>
                    {row[0].detalle.Observacion ? row[0].detalle.Observacion : "No hay observaciones"}
                  </td>
                </tr>
                </tbody>
                </table>
               {/*  {/* <h2 style={{ margin: "0.5rem",textAlign: "right" }}>Opciones del producto </h2> */}
               
              {row[0].opciones.length!==0 ?              
              <table>
                  <thead>
                    <th style={{ textAlign: "right" }}>Seccion</th>
                    <th style={{ textAlign: "right" }}>Opcion</th>
                    <th style={{ textAlign: "right" }}>Precio</th>
                  </thead>
                  <tbody>
                    {row[0].opciones.map((Opcion, i) => (
                      <tr>
                        <td style={{ textAlign: "right" }}>{Opcion.seccion}</td>
                        <td style={{ textAlign: "right" }}>
                        {Opcion.Nombre}
                        </td>
                        <td style={{ textAlign: "right" }}>$ {Number.parseFloat(Opcion.precio).toFixed(2)}</td>                      
                      </tr>
                    ))}
                  </tbody>
             </table>
             :null }             
           <br/>
           <br/>
           <br/>
              </>
              ))}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0.5rem",
              alignItems: "center",
            }}
          >
            <h3 style={{ marginRight: "2px" }}>Total a pagar:</h3>
            <p style={{ fontSize: "15px", margin: "1rem" }}>
              {"$ " + data.total}
            </p>
          </div>
        </div>

        {/* <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            margin: "2rem",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: "0.5rem" }}>Detalles del cliente </h2>
          <div style={{ margin: "2rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.5rem",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginRight: "2px" }}>Nombre:</h3>
              <p style={{ fontSize: "15px", margin: "1rem" }}>
                {data.client.name}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.5rem",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginRight: "2px" }}>Telefono:</h3>
              <p style={{ fontSize: "15px", margin: "1rem" }}>
                {data.client.phone}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.5rem",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginRight: "2px" }}>Dirección:</h3>
              <p style={{ fontSize: "15px", margin: "1rem" }}>
                {data.client.address}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.5rem",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginRight: "2px" }}>Precio:</h3>
              <p style={{ fontSize: "15px", margin: "1rem" }}>
                {"$ " +
                  new Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    currency: "COP",
                  }).format(data.total)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.5rem",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginRight: "2px" }}>Domicilio:</h3>
              <p style={{ fontSize: "15px", margin: "1rem" }}>
                {"$ " +
                  new Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    currency: "COP",
                  }).format(data.client.envio)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.5rem",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginRight: "2px" }}>Total a pagar:</h3>
              <p style={{ fontSize: "15px", margin: "1rem" }}>
                {"$ " +
                  new Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    currency: "COP",
                  }).format(data.total + data.client.envio)}
              </p>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
