
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  DialogTitle,
  Grid,
  Paper,
  Slide,
  Switch,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  InputLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Axios } from "../../../../config/axios";
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES,
  UPDATE_ORDEN_CATEGORY,
  GET_SECCIONES,
  GET_CAT_OPCIONES,
  DELETE_SECCION,
  EDIT_CATEGORY,
  CREATE_SECCION_OPCIONES,
  CREATE_OPCION,
  DELETE_OPCION,
  GET_STORES,
  UPDATE_CATEGORY_STATE,
  UPDATE_SECCION
} from "../../../../constants";
import { makeStyles } from "@material-ui/core/styles";

import Modal from 'react-modal'

import toast from "toasted-notes";

import Select from "react-dropdown-select";
import axios from "axios";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    margin: 0,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    maxHeight:425,
    overflow: 'auto',
    color: theme.palette.text.secondary,
  },
  title: {
    flexGrow: 1,
    margin: "1rem",
  },
  form: {
    margin: "2rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "55%",
  },
}));

const CategoriesStore = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [orden, setOrden] = useState([]);
  const [cat, setCat] = useState("");
  const [nombre, setNombre] = useState("");
  const [Edit, setEdit] = useState(false);
  const [EditCode, setEditCode] = useState();
  const [err, setErr] = useState(false);
  const [err2, setErr2] = useState(false);
  const [err3, setErr3] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [stores, setStores] = useState([]);
  const [TipoSelect, setTipoSelect] = useState({
    label: "Compleja",
    value: 1,
  });
  const [checked, setChecked] = React.useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const [OpcionesCat, setOpcionesCat] = useState([])
  //Opciones
  const [nombreOp, setNombreOp] = useState("");
  const [nombreSec, setNombreSec] = useState("");
  const [SeccionId, setSeccionId] = useState(1);
  const [opcionesT, setOpcionesT] = useState([]);
  const [opcionesC, setOpcionesC] = useState([]);
  const [opcionesL, setOpcionesL] = useState([]);
  const [opcionesA, setOpcionesA] = useState([]);
  const [opcionesS, setOpcionesS] = useState([]);
  const [opciones, setOpciones] = useState([]);
  const [formT, setFormT] = useState(false);
  const [formC, setFormC] = useState(false);
  const [formA, setFormA] = useState(false);
  const [formL, setFormL] = useState(false);
  const [formS, setFormS] = useState(false);
  const [adicionales, setAdicionales] = useState([]);
  
  

  const [tipo, setTipo] = React.useState("");

  const [tipos, setTipos] = useState([
    {
      label: "Compleja",
      value: 1,
    },
    {
      label: "Simple",
      value: 0,
    },
  ]);
  const [tipoSec, setTipoSec] = React.useState(1);
  const [tiposSec, setTiposSec] = useState([
    {
      label: "CheckBox",
      value: 2,
    },
    {
      label: "Precio",
      value: 1,
    },
  ]);

  const calcPosicion=(posicion)=>{    
    const index=orden.find((e)=>posicion===e.value);
    return index;
  }

  const getSecciones = async () => {
    Axios.get(`${GET_SECCIONES}`)
      .then((res) => {
        let data = res.data;
        setOpciones(res.data)
        let ot = [];
        let oc = [];
        let ol = [];
        let oa = [];
        let os = [];
        let ad = [];
        data.forEach((s) => {
          if (s.Id == 1) {
            ot.push(s);
          } else if (s.Id == 2) {
            oc.push(s);
          } else if (s.Id == 3) {
            ol.push(s);
          } else if (s.Id == 4) {
            oa.push(s);
          } else if (s.Id == 5) {
            os.push(s);
          }
        });
        setOpcionesT(ot);
        setOpcionesC(oc);
        setOpcionesL(ol);
        setOpcionesA(oa);
        setOpcionesS(os);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CatOpciones=async ()=>{
    Axios.get(GET_CAT_OPCIONES)
      .then((res) => {
        var array=Array.from(res.data)
        // var cat=array.filter((e)=>e.Nombre!=="Adicionales")
        setOpcionesCat(array)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSeccion=(e)=>{
    e.preventDefault();
    setErr2(false);
    if(nombreSec!==""){
      Axios.post(`${CREATE_SECCION_OPCIONES}`, {
        Nombre:nombreSec,
        Tipo:tipoSec
      })
        .then((res) => {
          setNombreSec("");
          CatOpciones();
          getSecciones();
          toast.notify("Seccion Creada Correctamente");
        })
        .catch((err) => {
          console.log(err);
          toast.notify("Hubo un error al crear la Seccion");
        });
    }else{
      setErr2(true);
    }

  }

  const getCategories = async () => {
    setOrden([])
    Axios.get(`${GET_CATEGORIES}?type=${0}`)
      .then((res) => {
        setCategories(res.data);
        var aux=[]
        for (let i = 1; i <= res.data.length; i++) {
          aux.push({
            value:i,
            label:i
          })          
        }
        setOrden(aux)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStores = async () => {
    Axios.get(GET_STORES)
      .then((res) => {
        let c = [];
        res.data.forEach((s) => {
          c.push({
            Codigo: s.Codigo,
          });
        });
        if(c.length===res.data.length){
          setCheckAll(true);
        }
        setChecked(c);
        setStores(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteCategory=(id)=>{
    Axios.delete(`${DELETE_CATEGORY}?id=${id}`)
      .then((res) => {
        getCategories();
        toast.notify("categoria Eliminada Correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.notify("Hubo un error al eliminar la categoria");
      });
  }


  const handleChange = (row) => {
    setOpen(true);
    setMsg(row.Estado == 1 ? "Inactivar" : "Activar");
    setCat(row);
  };

  const handleOpciones=(e)=>{
    e.preventDefault();
    setErr3(false);
    if(nombreOp!==""){
      Axios.post(`${CREATE_OPCION}`, {
        Nombre:nombreOp,
        SeccionId
      })
        .then((res) => {
          setNombreOp("");
          CatOpciones();
          getSecciones();
          toast.notify("Opcion Creada Correctamente");
        })
        .catch((err) => {
          console.log(err);
          toast.notify("Hubo un error al crear la Opcion");
        });
    }else{
      setErr3(true);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setErr(false);
    if (nombre != "") {
      if(Edit){        
        Axios.post(`${EDIT_CATEGORY}${EditCode}`, {
          nombre,
          tipo,
          tiendas: checked.map((c) => c.Codigo),
        })
          .then((res) => {
            getCategories();
            toast.notify("Categoría Actualizada Correctamente");
          })
          .catch((err) => {
            console.log(err);
            toast.notify("Hubo un error al Actualizar la categoría");
          });

      }else{
        Axios.post(`${CREATE_CATEGORY}`, {
          nombre,
          tipo,
          Orden:categories.length +1,
          tiendas: checked.map((c) => c.Codigo),
        })
          .then((res) => {
            setNombre("");
            getCategories();
            toast.notify("Categoría Creada Correctamente");
          })
          .catch((err) => {
            console.log(err);
            toast.notify("Hubo un error al crear la categoría");
          });
      }
    } else {
      setErr(true);
    }
  };

  const handleState = () => {
    Axios.post(UPDATE_CATEGORY_STATE, {
      codTienda: -1,
      codCategoria: cat.Codigo,
      estado: cat.Estado == 1 ? 0 : 1,
    })
      .then((res) => {
        getCategories();
        toast.notify(
          `Categoria ${
            cat.Estado == 0 ? "Activada" : "Inactivada"
          } correctamente`
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.findIndex((c) => c.Codigo == value.Codigo);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push({
        Codigo: value.Codigo,
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const SelectAll=()=>{
    const newChecked = [...checked];
    stores.map((store)=>{
      const currentIndex = newChecked.findIndex((c) => c.Codigo === store.Codigo);      
      if (currentIndex === -1) {
        if(!checkAll){
          newChecked.push({
            Codigo: store.Codigo,
          });
        }
      } else {
        console.log("posicion",currentIndex);
        if(checkAll){
          newChecked.splice(currentIndex, 1);
        }
      }      
    })
    setChecked(newChecked);
    setCheckAll(!checkAll)
  }

  const updateOrden=(codigo,index)=>{
    const index2=categories.findIndex((e)=>index[0].value===e.Orden);
    //if(categories[index2]!==undefined){
      Axios.post(`${UPDATE_ORDEN_CATEGORY}?codigo=${codigo}&index=${index[0].value}`)
      .then((res) => {        
      })
      .catch((err) => {
        console.log(err);
        toast.notify("Hubo un error al Actualizar El orden");
      });
    /* }else{
      toast.notify("Esta posicion ya fue seleccionada");
    } */
  }

  useEffect(() => {
    CatOpciones();
    getSecciones();
    getCategories();
    getStores();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item sm={7}>
            <Paper className={classes.paper}>
            <h2 style={{textAlign:"left"}}>CATEGORÍAS</h2>
              <TableContainer style={{ maxHeight: 500 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 70 }}>Codigo</TableCell>
                      <TableCell style={{ minWidth: 140 }}>Nombre </TableCell>
                      <TableCell style={{ minWidth: 140 }}>Estado </TableCell>
                      <TableCell style={{ minWidth: 140 }}>Orden </TableCell>
                      <TableCell style={{ minWidth: 140 }}>Editar </TableCell>
                      <TableCell style={{ minWidth: 140 }}>Eliminar </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categories.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          style={{ color: "#2e1156", cursor: "pointer" }}
                          component="th"
                          scope="row"
                        >
                          {row.Codigo}
                        </TableCell>
                        <TableCell>{row.Descripcion}</TableCell>
                        <TableCell>
                          <Switch
                            checked={row.Estado == 1 ? true : false}
                            onChange={() => handleChange(row)}
                            color="primary"
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </TableCell>
                        <TableCell>
                       {orden.length!==0? <Select
                      style={{
                        marginTop: "1rem",
                        borderRadius: "7px",
                        height: "45px",
                        width: "55px",
                      }}
                      color="#AC7A47"
                      options={orden}
                      valueField="value"
                      labelField="label"
                      onChange={(value) => updateOrden(row.Codigo,value)}
                      values={[ calcPosicion(row.Orden) ]}
                    />:null}
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                    <EditOutlinedIcon
                      style={{ margin: "1rem" }}
                       onClick={() => {
                        setEditCode(row.Codigo)
                        setEdit(true);
                        const index=tipos.find((e)=>e.value===row.Tipo)
                        setTipoSelect(index)
                        setNombre(row.Descripcion);
                        setTipo(row.Tipo);
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" style={{ cursor: "pointer" }}>
                          <DeleteIcon
                            onClick={() => {
                              DeleteCategory(row.Codigo)
                            }}
                          />
                  </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <br/>
            
            <Paper className={classes.paper}>
            <h2 style={{textAlign:"left"}}>OPCIONES</h2>           
          {            
           OpcionesCat.length!==0&&opciones.length!==0?
           OpcionesCat.map((cat)=>(
            <div style={{marginTop:10,marginBottom:10}}>
            <Opciones cat={cat} ops={opciones} setOpcionesCat={setOpcionesCat} OpcionesCat={OpcionesCat} getSecciones={getSecciones}/>
           </div>
           ))
          :null
          }

          
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper className={classes.paper}>
              <Typography
                component="h2"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                Nueva Categoría
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className={classes.form}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    columnGap: "10px",
                  }}
                >
                  <TextField 
                    style={{marginTop: "30px"}}
                    id="standard-basic"
                    label="Nombre"
                    variant="outlined"
                    onChange={(e) => setNombre(e.target.value)}
                    value={nombre}
                    error={err}
                    helperText={err && "Campo obligatorio"}
                  />
                  <div>
                    <InputLabel>Tipo de categoria</InputLabel>
                    <Select
                      style={{
                        marginTop: "1rem",
                        borderRadius: "7px",
                        height: "55px",
                      }}
                      color="#AC7A47"
                      options={tipos}
                      labelField="label"
                      valueField="value"
                      onChange={(value) => setTipo(value[0].value)}
                      values={[TipoSelect]}
                    />
                  </div>
                </div>                    
                <List className={classes.list}>
                <FormControlLabel
           control={<Checkbox  name="checkedA" onChange={()=>SelectAll() } checked={checkAll} color="primary"/>}
           label="Selecionar todas"
          />  
                  {stores.map((value, i) => {
                    const labelId = `checkbox-list-label-${value.Codigo}`;

                    return (<>                      
                      <ListItem
                        key={i}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(value)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={
                              checked.findIndex(
                                (c) => c.Codigo == value.Codigo
                              ) == -1
                                ? false
                                : true
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                            color="primary"
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${value.Nombre}`}
                        />
                      </ListItem>
                      </>
                    );
                  })}
                </List>
                <Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#AC7A47",
                    color: "white",
                    borderRadius: "7px",
                  }}
                  className={classes.submit}
                >
                  {Edit?"Actualizar Categoria":"Registrar Categoría"}
                </Button>
                {Edit?<Button
                  type="button"
                  onClick={()=>{
                    setEdit(false);
                  }}
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#AC7A47",
                    color: "white",
                    borderRadius: "7px",
                  }}
                  className={classes.submit}
                >
                  Cancelar
                </Button>
                :null}
                </Grid>
              </form>
            </Paper>
            <br/>
            <Paper className={classes.paper}>
            <div>
              <Typography
                component="h2"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                Nueva Categoria Opciones
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSeccion}
                className={classes.form}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems:"center",
                    justifyContent:"space-between"
                  }}
                >
                  <TextField                     
                    id="standard-basic"
                    label="Nombre"
                    variant="outlined"
                    onChange={(e) => setNombreSec(e.target.value)}
                    value={nombreSec}
                    error={err2}
                    helperText={err2 && "Campo obligatorio"}
                  />
                  <Select
                      style={{
                        marginTop: "1rem",
                        borderRadius: "7px",
                        height: "55px",
                      }}
                      color="#AC7A47"
                      options={tiposSec}
                      labelField="label"
                      valueField="value"
                      onChange={(value) => setTipoSec(value[0].value)}
                      values={[tiposSec[0]]}
                    />               
                </div>                                   
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#AC7A47",
                    color: "white",
                    borderRadius: "7px",
                  }}
                  className={classes.submit}
                >
                  Registrar Opcion
                </Button>
              </form>
              </div>
              <div>
              <Typography
                component="h2"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                Nueva Opcion
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleOpciones}
                className={classes.form}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    columnGap: "10px",
                  }}
                >
                  <TextField 
                    style={{marginTop: "30px"}}
                    id="standard-basic"
                    label="Nombre"
                    variant="outlined"
                    onChange={(e) => setNombreOp(e.target.value)}
                    value={nombreOp}
                    error={err3}
                    helperText={err3 && "Campo obligatorio"}
                  />
                  <div>
                    <InputLabel>Tipo de Opcion</InputLabel>
                   {OpcionesCat.length!==0? <Select
                      style={{
                        marginTop: "1rem",
                        borderRadius: "7px",
                        height: "55px",
                        textAlign:"center"
                      }}
                      color="#AC7A47"
                      options={OpcionesCat}
                      labelField="Nombre"
                      valueField="id"
                      onChange={(value) => {setSeccionId(value[0].Id)}}
                      values={[OpcionesCat[0]]}
                    />
                  :null}
                  </div>
                </div>                                   
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#AC7A47",
                    color: "white",
                    borderRadius: "7px",
                  }}
                  className={classes.submit}
                >
                  Registrar Opcion
                </Button>
              </form>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Vas a {msg} una Categoría
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Estas seguro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleState} color="primary">
            Si
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


const Opciones=({ops,cat,getSecciones,setOpcionesCat,OpcionesCat})=>{
  const classes = useStyles();
  const [opciones, setOpciones] = useState([]);
  const [form, setForm] = useState(false);
  const [nombreSeccion, setNombreSeccion] = useState("")
  const [id, setId] = useState("")
  
  const [nombreSec, setNombreSec] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [err2, setErr2] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const DeleteOpcion=(id)=>{
    Axios.delete(`${DELETE_OPCION}?id=${id}`)
      .then((res) => {
        CatOpciones();
        getSecciones();
        toast.notify("Opcion Eliminada Correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.notify("Hubo un error al eliminar la Opcion");
      });
  }

  const DeleteSeccion=(id)=>{
    Axios.delete(`${DELETE_SECCION}?id=${id}`)
    .then((res) => {
      CatOpciones();
      getSecciones();
      toast.notify("Seccion Eliminada Correctamente");
    })
    .catch((err) => {
      console.log(err);
      toast.notify("Hubo un error al eliminar la Opcion");
    });
  }

  const CatOpciones=async ()=>{
    Axios.get(GET_CAT_OPCIONES)
      .then((res) => {
        var array=Array.from(res.data)
        var cat=array.filter((e)=>e.Nombre!=="Adicionales")
        setOpcionesCat(cat)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const GetOpciones=()=>{
    let ot = [];
    ops.map((s) => {
      if (s.Id == cat.Id) {
        ot.push(s);
    }
  });
    setOpciones(ot);
  }

  const modifySeccion = (data) => {
    let ids = data.Id
    let nombre = data.Nombre
    setNombreSeccion(nombre)
    setId(ids)
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const editarSeccion = (e) =>{
    e.preventDefault()
    Axios.post(UPDATE_SECCION,{
      idSeccion:id,
      nombreSeccion:nombreSec
    }).then((res)=>{
      toast.notify(res.data.Mensaje);
      setTimeout(() => {
        setOpenModal(false)
        window.location.reload()
      }, 1000);
    }).catch((err)=>{
      toast.notify("Hubo un error al actualizar, por favor intente nuevamente");
    })
  }

  useEffect(() => {
    GetOpciones();
  }, [OpcionesCat,ops])


  return(
    <>
    <div>
       
          <Grid container xs={10} justify="space-around">
          <Grid xs={4}>
          <h3>{cat.Nombre}</h3>
          </Grid>
          <Grid xs={4}>
            <IconButton
              aria-label="delete"
              style={{
                backgroundColor: "#AC7A47",
                color: "white",
              }}
              onClick={() => setForm(!form)}
            >
              {form?
                <RemoveCircle fontSize="small"/>
              :
              <AddCircleIcon fontSize="small" />
              }
            </IconButton>
            </Grid>
            <DeleteIcon
              onClick={() => {
              DeleteSeccion(cat.Id)
            }} 
            />
          
            <EditOutlinedIcon
            style={{ margin: "0.0rem", cursor:"pointer" }}
            onClick={() => modifySeccion(cat)}
            />
                  
        </Grid> 
          {form?
          opciones.length > 0 && (
            <TableContainer component={Paper}>
              <Table
                //className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>                      
                    <TableCell align="center">Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {opciones.map((row) => (
                    <TableRow key={row.Nombre}>
                      <TableCell component="th" scope="row">
                        {row.Nombre}
                      </TableCell>                      
                      <TableCell align="center" style={{ cursor: "pointer" }}>
                        <DeleteIcon
                          onClick={() => {
                            DeleteOpcion(row.OpcionId)
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ):
          null
          }
      </div>
      <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Paper className={classes.paper}>
            <div>
              <Typography
                component="h2"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                Modificación de sección
              </Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={editarSeccion}
                className={classes.form}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems:"center",
                    justifyContent:"space-between"
                  }}
                >
                  <TextField                     
                    id="standard-basic"
                    label="Nombre"
                    variant="outlined"
                    onChange={(e) => setNombreSec(e.target.value)}
                    placeholder={nombreSeccion}
                    error={err2}
                    helperText={err2 && "Campo obligatorio"}
                  />
                </div>                                   
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#AC7A47",
                    color: "white",
                    borderRadius: "7px",
                  }}
                  className={classes.submit}
                >
                  Actualizar
                </Button>
              </form>
              </div>
            </Paper>
      </Modal>
      </div>
      </>
  )
}



export default CategoriesStore;
