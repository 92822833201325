import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { Typography } from "@material-ui/core";

export default function ChartsDay({ hours }) {
  const theme = useTheme();

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          component="h2"
          variant="h6"
          style={{ color: "#707070" }}
          gutterBottom
        >
          Ventas Día por hora
        </Typography>
      </div>

      <ResponsiveContainer>
        <LineChart
          width={500}
          height={300}
          data={hours}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="hour" />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="sale"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
