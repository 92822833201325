import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formatPrice, decimalTwo } from "../../../utils/price";
import CountUp from "react-countup";
import AnimatedNumber from "animated-number-react";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Fact({ value, text, color, isNumber }) {
  const classes = useStyles();

  const formatValue = (value) => `$ ${formatPrice(decimalTwo(value))}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        style={{ color, fontWeight: "bold" }}
      >
        {!isNumber ? (
          <AnimatedNumber
            value={value}
            formatValue={formatValue}
            duration={1200}
          />
        ) : (
          <CountUp end={value ? value : 0} />
        )}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {text}
      </Typography>
    </div>
  );
}
