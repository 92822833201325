import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  LinearProgress,
  IconButton,
  Collapse,
  Box,
  Typography,
  TextField,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import clsx from "clsx";

import { Axios } from "../../../config/axios";
import AdminContext from "../../../context/admin/AdminContext";
import Orders from "./OrdersCustomers";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Select from "react-dropdown-select";

import ReactExport from "react-export-excel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import toast from "toasted-notes";
import {
  GET_ORDERS_CUSTOMER,
  GET_ORDERS_DETAILS,
  GET_STORES,
} from "../../../constants";
import moment from "moment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
var DetailsOrd = [];
var detallesPedidos = [];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 100,
  },
  fixedHeightMin: {
    height: 170,
  },
}));
const filter = createFilterOptions();
function Row(props) {
  const { row } = props;
  const { index } = props;
  const { OrdersFilter } = props;
  const { dataExcel } = props;
  // dataExcel, setdataExcel
  const { setOrdersDetails } = props;
  const [open, setOpen] = React.useState(false);
  const [Details, setDetails] = useState([]);
  const [detailProduct, setDetailProduct] = useState({})
  const classes = useRowStyles()

  const getDetails = async () => {
    setTimeout(() => {
      Axios.get(GET_ORDERS_DETAILS + row.NumeroPedido)
        .then((res) => {
          const product = {
            Nombre: res.data[row.indexArticuloPedido].Nombre,
            Cantidad: res.data[row.indexArticuloPedido].Cantidad,
            ITBMS: res.data[row.indexArticuloPedido].Iva,
            Precio: (res.data[row.indexArticuloPedido].Precio).toFixed(2),
          }
          setDetailProduct(product)
          const newDataExcel = dataExcel;
          const tempDataExcel = Object.assign(dataExcel[index], product);
          newDataExcel[index] = tempDataExcel
          props.setdataExcel(newDataExcel);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
  };
  useEffect(() => {
    getDetails();
  }, [row]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.nombreTienda}
        </TableCell>
        <TableCell align="center">{row.NumeroPedido}</TableCell>
        <TableCell align="center">{row.EstadoPedido}</TableCell>
        <TableCell align="center">
          {row.FechaCreacion?.replace("T", " ")}
        </TableCell>
        <TableCell align="center">
          {row.fechaAceptado?.replace("T", " ")}
        </TableCell>
        <TableCell align="center">
          {row.fechaCancelado?.replace("T", " ")}
        </TableCell>
        <TableCell align="center">{row.causalRechazo}</TableCell>
        <TableCell align="center">{row.direcionTienda}</TableCell>
        <TableCell align="center">{row.nombreCliente}</TableCell>
        <TableCell align="center">
          {Number.parseFloat(row.valor).toFixed(2)}
        </TableCell>
        <TableCell align="center">{row?.formaPago}</TableCell>
        <TableCell align="center">{row?.descuento}</TableCell>
        {/* <TableCell align="center">{row?.cantidadArticulos}</TableCell> */}
        <TableCell align="center">{detailProduct?.Nombre}</TableCell>
        <TableCell align="center">{detailProduct?.Cantidad}</TableCell>
        <TableCell align="center">{Number.parseFloat(detailProduct.ITBMS).toFixed(2)}</TableCell>
        <TableCell align="center">$ {Number.parseFloat(detailProduct.Precio).toFixed(2)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Customers = () => {
  const { admin } = React.useContext(AdminContext);
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState("");
  const [backup, setBackup] = useState([]);
  const [charts, setCharts] = useState([]);
  const [reporte, setReporte] = useState(false);
  const [value, setValue] = useState(null);
  const [user, setUser] = useState([]);
  const [user2, setUser2] = useState([]);
  const [resTiendas, setResTiendas] = useState([]);
  const [test, setTest] = useState();
  const [codigo, setCodigo] = useState(0);
  const [dataExcel, setdataExcel] = useState([]);
  const [totalPedidos, setTotalPedidos] = useState(0);
  //
  const [FilterValor, setFilterValor] = useState("");
  const [OrdersFilter, setOrdersFilter] = useState([]);
  const [beforeDate, setBeforeDate] = React.useState(new Date());
  const [nextDate, setNextDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(true);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixed = clsx(classes.paper, classes.fixedHeightMin);

  const getCustomers = () => {
    let año = new Date();
    let fechaIni = año.toISOString();
    let fechaFin = año.toISOString();
    Axios.get(
      `${GET_ORDERS_CUSTOMER}fechaInicial=${fechaIni}&fechaFinal=${fechaFin}&codTienda=${-1}`
    )
      .then((res) => {
        setUser2(res.data);
        setBackup(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStores = async () => {
    Axios.get(GET_STORES)
      .then((res) => {
        res.data.unshift({
          Codigo: -1,
          Nombre: "TODAS",
        });
        setLoading(false);
        setCodigo(-1)
        setStores(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStore = async (item) => {
    if (item.Nombre == "TODAS") {
      getCustomers();
      return;
    }
    setStore(item);
    if (item.Codigo == -1) {
      getStores();
    } else {
      setCodigo(item.Codigo);
    }
  };

  const SearchTerm = async (e) => {
    console.log(e);

    const data = user2.filter((element) => element.nombreCliente == e);

    console.log(data);
    data.length > 0 ? setUser2(data) : console.log("no hay incidencias : " + e);
  };

  const filterDate = async () => {
    setLoading(true);
    setdataExcel([]);
    setUser2([]);
    setTotalPedidos(0);

    const valid = moment(moment(nextDate).format("YYYY-MM-DD")).isAfter(
      moment(beforeDate).format("YYYY-MM-DD")
    );
    const x = moment(nextDate).diff(moment(beforeDate), "minute");
    // console.log(x);
    try {
      if (valid || x === 0) {
        const initialDate = moment(beforeDate).format("YYYY-MM-DD");
        const finalDate = moment(nextDate).format("YYYY-MM-DD");
        const idTienda = codigo == 0 ? -1 : codigo;
        detallesPedidos = [];
        const { data } = await Axios.get(
          `${GET_ORDERS_CUSTOMER}fechaInicial=${initialDate}&fechaFinal=${finalDate}&codTienda=${idTienda}`
        )
        setTotalPedidos(data.length);
        // let dataModified = [];
        // data.map(detail => {
        //   // const resDetail =  await Axios.get(GET_ORDERS_DETAILS + detail.NumeroPedido)
        //   for (let i = 0; i < detail.cantidadArticulos; i++) {
        //     let objectDetail = {
        //       ...detail,
        //       indexArticuloPedido: i
        //     };
        //     dataModified.push(objectDetail);
        //   }
        // })
        console.log(data);
        setdataExcel(data);
        setUser2(data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.notify("Las fechas no son validas");
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCustomers();
    getStores();
  }, []);

  if (loading)
    return (
      <>
        <LinearProgress color="primary" style={{ margin: "1rem" }} />
      </>
    );

  return (
    <>
      {!reporte ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-Between",
                alignItems: "center",
                width: "70%",
              }}
            >
              <Select
                style={{
                  borderRadius: "7px",
                  height: "55px",
                  width: 250,
                  margin: "1rem",

                }}
                color="#707070"
                options={stores}
                labelField="Nombre"
                valueField="Codigo"
                onChange={(value) => handleStore(value[0])}
                values={[
                  store
                    ? store
                    : {
                      Nombre: "Seleccione tienda",
                    },
                ]}
              />
              <Grid item xs={12} md={6} lg={10}>
                <div
                  style={{
                    borderRadius: "7px",
                    height: "87px",
                    width: 300,
                    margin: "auto",

                  }}
                >
                  <TextField
                    label="Buscar cliente"
                    margin="normal"
                    onChange={(e) => SearchTerm(e.target.value)}
                    variant="outlined"
                  />
                </div>
              </Grid>


            </div>
          </Grid>
          {/* seccion de filtros */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} md={6} lg={8}
              container
              justify="center"
              style={{ marginLeft: "1rem" }}
              spacing={2}
              width="5%"
            >
              <Grid item xs={12} md={4} lg={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="none"
                  width="50px"
                  id="date-picker-inline"
                  label="Fecha Inicial"
                  value={beforeDate}
                  onChange={(date) => setBeforeDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="none"
                  width="50%"
                  id="date-picker-inline"
                  label="Fecha Final"
                  value={nextDate}
                  onChange={(date) => setNextDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={6}>
                <Button
                  variant="contained"
                  style={{ color: "#fff", backgroundColor: "#0a82ec" }}
                  onClick={filterDate}
                >
                  Filtrar
                </Button>
                {/* exportar */}
                <ExcelFile
                  element={
                    <Button
                      style={{
                        backgroundColor: "#1f6f43",
                        color: "white",
                        borderRadius: "7px",
                        height: "39px",
                        width: 141,
                        marginLeft: "1rem",
                      }}
                      endIcon={<GetAppIcon />}
                    >
                      Export excel
                    </Button>
                  }
                >
                  <ExcelSheet data={user2} name="Pedidos">
                    <ExcelColumn label="Nombre Tienda" value="nombreTienda" />
                    <ExcelColumn label="ID Pedido" value="NumeroPedido" />
                    <ExcelColumn label="Estado Pedido" value="EstadoPedido" />
                    <ExcelColumn label="Fecha Creacion" value="FechaCreacion" />
                    <ExcelColumn label="fecha Aceptado" value="fechaAceptado" />
                    <ExcelColumn label="fecha Cancelado" value="fechaCancelado" />
                    <ExcelColumn label="Motivo Rechazo" value="causalRechazo" />
                    <ExcelColumn label="Direciòn Tienda" value="direcionTienda" />
                    <ExcelColumn label="Nombre Cliente" value="nombreCliente" />
                    <ExcelColumn label="Total Pedido" value="valor" />
                    <ExcelColumn label="Mètodo Pago" value="formaPago" />
                    <ExcelColumn label="Descuento" value="descuento" />
                    <ExcelColumn label="Productos" value="detalle"/>
                    {/* <ExcelColumn label="Nombre Producto" value="Nombre" />
                    <ExcelColumn label="Cantidad Producto" value="Cantidad" />
                    <ExcelColumn label="ITBMS" value="ITBMS" />
                    <ExcelColumn label="Precio" value="Precio" /> */}
                  </ExcelSheet>
                </ExcelFile>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          {/* fin Filtros */}

          <Grid item xs={12}>
            <div>
              <p>
                Total Pedidos = {totalPedidos}
              </p>
              <p>
                Total Registros encontrados = {user2.length}
              </p>
            </div>
            <TableContainer style={{ maxHeight: 470 }}>
              <Table stickyHeader padding="checkbox" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell style={{ minWidth: 80 }}>Detalles</TableCell> */}
                    <TableCell style={{ minWidth: 170 }}>
                      Nombre Tienda
                    </TableCell>
                    <TableCell style={{ minWidth: 100 }}>Id Pedido </TableCell>
                    <TableCell style={{ minWidth: 130 }}>
                      Estado Pedido{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 140 }}>
                      Fecha Creacion{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 140 }}>
                      Fecha Aceptado{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 140 }}>
                      Fecha Cancelado{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>
                      Motivo Cancelaciòn{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 300 }}>
                      Direcciòn Tienda{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 300 }}>
                      Nombre Cliente{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>
                      Total Pedido{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>
                      Metodo Pago{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>Descuento</TableCell>
                    {/* <TableCell style={{ minWidth: 80 }}>Productos</TableCell> */}
                    <TableCell style={{ minWidth: 300 }}>
                      Productos{" "}
                    </TableCell>
                    {/* <TableCell style={{ minWidth: 80 }}>
                      Cantidad Articulo
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>
                      ITBMS
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>
                      Precio
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user2
                    ? user2.map((row, i) => (
                      <TableRow 
                      key={row.NumeroPedido}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>{row.nombreTienda}</TableCell>
                        <TableCell>{row.NumeroPedido}</TableCell>
                        <TableCell>{row.EstadoPedido}</TableCell>
                        <TableCell>{moment(row.FechaCreacion).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                        <TableCell>{row.fechaAceptado == null ? row.fechaAceptado : moment(row.fechaAceptado).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                        <TableCell>{row.fechaCancelado == "" ? row.fechaCancelado : moment(row.fechaCancelado).format("YYYY-MM-DD h:mm:ss")}</TableCell>
                        <TableCell>{row.causalRechazo}</TableCell>
                        <TableCell>{row.direcionTienda}</TableCell>
                        <TableCell>{row.nombreCliente}</TableCell>
                        <TableCell>{row.valor}</TableCell>
                        <TableCell>{row.formaPago}</TableCell>
                        <TableCell>{row.descuento}</TableCell>
                        <TableCell>{row.detalle}</TableCell>
                      </TableRow>
                    ))
                    : console.log(user2)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        <>
          <Orders customer={customer} setReporte={setReporte} />
        </>
      )}
    </>
  );
};