import React, { useEffect,useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Typography, Button, InputLabel, Grid, Paper } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Axios } from "../../../config/axios";
import Direccion from "../../../utils/Direccion";
import {
  GET_DEPARTMENTS,
  GET_MUNICIPALITIES,
  CREATE_STORE,
  GET_CATEGORIES,
  UPDATE_STORE
} from "../../../constants";
import Select from "react-dropdown-select";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { getTime } from "../../../utils/date";
import toast from "toasted-notes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    padding:20,
    alignItems: "center",
    justifyContent: "center",
  },
  text:{
    width:"100%",
    marginTop:6,
    marginBottom:6
  },
  // paper: {
  //   padding: theme.spacing(2),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // },
  title: {
    flexGrow: 1,
    margin: "1rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const ShopForm = ({ setForm,type,shop }) => {
  const classes = useStyles();
  const RefLat = useRef();
  const [deps, setDeps] = useState([]);
  const [dep, setDep] = useState("");
  const [munis, setMunis] = useState([]);
  const [mun, setMun] = useState("");
  const [horaInicial, setHoraInicial] = useState(new Date());
  const [horaFinal, setHoraFinal] = useState(new Date());
  const [categories, setCategories] = useState([]);
  const [cat, setCat] = useState("");


  const [error, setError] = useState({});
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [Cobertura, setCobertura] = useState(true); 
  const [departamento, setDepartamento] = useState("NONE");
  const [municipio, setMunicipio] = useState("NONE");
  const [Barrio, setBarrio] = useState("");
  const [ObservacionesAd, setObservacionesAd] = useState(type==="editar"? shop.ObservacionesAd: "");
  const [direccion, setDireccion] = useState();  
  const [referencia, setReferencia] = useState();
  const [complemento, setComplemento] = useState();
  const [latitud, setLatitud] = useState(type==="editar"? shop.Latitud: "");
  const [longitud, setLongitud] = useState(type==="editar"? shop.Longitud: "");

  const getDepartments = async () => {
    Axios.get(GET_DEPARTMENTS)
      .then((res) => {
        setDeps(res.data);
        if(shop){
          const currentIndex = res.data.findIndex((c) => c.Codigo == shop.CodDepartamento);
          if(currentIndex === -1){
            const currentIndex2 = res.data.findIndex((c) => c.Codigo == 154);
            setDep();
            console.log("Departamento ",res.data[currentIndex2]);
          }else{
            setDep(res.data[currentIndex]);
          }
        }else{
          const currentIndex2 = res.data.findIndex((c) => c.Codigo == 154);
          console.log("Departamento ",res.data[currentIndex2]);
          setDep(res.data[currentIndex2]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMunicipalities = (id) => {
    Axios.get(GET_MUNICIPALITIES + 154)
      .then((res) => {
        setMunis(res.data);
        if(shop){
          const currentIndex = res.data.findIndex((c) => c.Codigo == shop.CodMunicipio);
          if(currentIndex === -1){
            const currentIndex2 = res.data.findIndex((c) => c.Codigo == 5461);
            console.log("municipio ",res.data[currentIndex2]);
            setMun(res.data[currentIndex2]);
          }else{
            
            setMun(res.data[currentIndex]);
          }
        }else{
          const currentIndex2 = res.data.findIndex((c) => c.Codigo == 5461);
          console.log("municipio ",res.data[currentIndex2]);
          setMun(res.data[currentIndex2]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategories = async () => {
    Axios.get(`${GET_CATEGORIES}?type=${1}`)
      .then((res) => {
        console.log(res.data)
        setCategories(res.data);
        setCat(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getPosition=()=> {
    // Simple wrapper
    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
    });
}

const getLat=async()=>{
  var position = await getPosition();  
  var lat =position.coords.latitude;
  return lat;
}

  useEffect(() => {
    getDepartments();
    getMunicipalities();
    getCategories();       
  }, []);

  const formik = useFormik({
    initialValues: {
      nit: type==="editar"? shop.Nit: "",
      nombre: type==="editar"? shop.Nombre: "",
      representante: type==="editar"? shop.Representante: "",
      razonSocial: type==="editar"? shop.RazonSocial: "",
      telefono: type==="editar"? shop.Telefono: "",
      celular1: type==="editar"? shop.Celular1: "",
      celular2: type==="editar"? shop.Celular2: "",
      direccion: type==="editar"? shop.Direccion: "",
      Barrio: type==="editar"? shop.Barrio: "",
      usuario: type==="editar"? shop.Usuario: "",
      clave: type==="editar"? shop.Clave: "",
      valorDomicilio: type==="editar"? shop.ValorDomicilio: 0,
      valorPedidoMinimo: type==="editar"? shop.ValorPedidoMinimo: 0,
      rangoEnMetros: type==="editar"? shop.RangoEnMetros: 0,
      latitud:type==="editar"? shop.Latitud: "",
      longitud:type==="editar"? shop.Longitud: ""
    },
    validationSchema: Yup.object({
      nit: Yup.string().required("El Nit es requerido"),
      nombre: Yup.string().required("El Nombre es requerido"),
      representante: Yup.string().required("El Representante es requerido"),
      razonSocial: Yup.string().required("La Razon social es requerida"),
      direccion: Yup.string().required("La Direccion es requeridad"),
      Barrio: Yup.string().required("​El Barrio es requerido"),
      telefono: Yup.string().required("El telefono es requerido"),
      usuario: Yup.string().required("El Usuario es requerido"),
      latitud: Yup.string().required("la latitud es requirida"),
      longitud: Yup.string().required("la longitud es requirida"),
      clave: Yup.string().required("La Clave es requerida"),      
      valorDomicilio: Yup.number()
        .min(0, "El valor del domicilio no puede ser 0")
        .required("El Valor del domicilio es requerido"),
      valorPedidoMinimo: Yup.number()
        .required("El Valor del pedido minimo es requerido"),
      rangoEnMetros: Yup.number()
        .min(0, "El rango de metros no puede ser 0")
        .required("El Rango en metros es requerido"),
    }),
    onSubmit: (values) => {
      //Nit, Nombre, Representante, RazonSocial, Telefono, Celular1, Celular2, Direccion, Usuario, Clave, ValorDomicilio, ValorPedidoMinimo, RangoEnMetros, CodMunicipio
      const data = {
        nit: values.nit,
        nombre: values.nombre,
        representante: values.representante,
        razonSocial: values.razonSocial,
        telefono: values.telefono,
        celular1: values.celular1,
        celular2: values.celular2,
        direccion: values.direccion,
        usuario: values.usuario,
        clave: values.clave,
        valorDomicilio: values.valorDomicilio,
        valorPedidoMinimo: values.valorPedidoMinimo,
        latitud: values.latitud,
        longitud : values.longitud,
        rangoEnMetros: values.rangoEnMetros,
        codMunicipio: 5461,
        CodDepartamento: 154,
        Barrio:values.Barrio,
        ObservacionesAd:ObservacionesAd,
        codCategoria: -1,
      };      
      if(type==="crear"){
        console.log("creando producto..", data);
        Axios.post(CREATE_STORE, data)
        .then((res) => {
          console.log(res);
          toast.notify("Tienda Creada Correctamente");
          setForm(false);
        })
        .catch((err) => {
          console.log(err);
          toast.notify("Hubo un error al crear la tienda");
        });
      }else if(type==="editar"){
          data.Codigo=shop.Codigo;
          console.log("editando producto.....", data);       
          Axios.post(UPDATE_STORE, data)
        .then((res) => {
          console.log(res);
          toast.notify("Tienda Actualizada Correctamente");
          setForm(false);
        })
        .catch((err) => {
          console.log(err);
          toast.notify("Hubo un error al Actualizada la tienda");
        });
      }
    },
  });

  const handleChange = (value) => {
    setDepartamento(value)
    getMunicipalities(value[0].Codigo);
  };


  return (
    <div>
      <div className={classes.root}>
       <form noValidate autoComplete="off" onSubmit={formik.handleSubmit} >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {type==="crear"
              ?
              (
                <Typography
                component="h3"
                variant="h6"
                style={{ color: "#707070" }}
                noWrap
              >
                Nueva Tienda
              </Typography>
              )
              :
              <Typography
              component="h3"
              variant="h6"
              style={{ color: "#707070" }}
              noWrap
            >
              Actualizar Tienda
            </Typography>
              }
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              
                <TextField
                  id="standard-basic"
                  label="Nit"
                  name="nit"
                  variant="outlined"
                  onChange={formik.handleChange}
                  className={classes.text}
                  value={formik.values.nit}
                  helperText={formik.touched.nit && formik.errors.nit}
                  error={formik.touched.nit && formik.errors.nit ? true : false}
                />
                <TextField
                  id="standard-basic"
                  label="Nombre"
                  name="nombre"
                  variant="outlined"
                  autoFocus
                  onChange={formik.handleChange}
                  className={classes.text}
                  value={formik.values.nombre}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                  error={
                    formik.touched.nombre && formik.errors.nombre ? true : false
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Representante"
                  name="representante"
                  variant="outlined"
                  onChange={formik.handleChange}
                  className={classes.text}
                  value={formik.values.representante}
                  helperText={
                    formik.touched.representante && formik.errors.representante
                  }
                  error={
                    formik.touched.representante && formik.errors.representante
                      ? true
                      : false
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Usuario"
                  name="usuario"
                  variant="outlined"
                  onChange={formik.handleChange}
                  className={classes.text}
                  value={formik.values.usuario}
                  helperText={formik.touched.usuario && formik.errors.usuario}
                  error={
                    formik.touched.usuario && formik.errors.usuario
                      ? true
                      : false
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Clave"
                  name="clave"
                  type="password"
                  variant="outlined"
                  onChange={formik.handleChange}
                  className={classes.text}
                  value={formik.values.clave}
                  helperText={formik.touched.clave && formik.errors.clave}
                  error={
                    formik.touched.clave && formik.errors.clave ? true : false
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Razon Social"
                  name="razonSocial"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.razonSocial}
                  className={classes.text}
                  helperText={
                    formik.touched.razonSocial && formik.errors.razonSocial
                  }
                  error={
                    formik.touched.razonSocial && formik.errors.razonSocial
                      ? true
                      : false
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Telefono"
                  name="telefono"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.telefono}
                  className={classes.text}
                  helperText={formik.touched.telefono && formik.errors.telefono}
                  error={
                    formik.touched.telefono && formik.errors.telefono
                      ? true
                      : false
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Celular1"
                  name="celular1"
                  variant="outlined"
                  onChange={formik.handleChange}
                  className={classes.text}
                  value={formik.values.celular1}
                />
                <TextField
                  id="standard-basic"
                  label="Celular2"
                  name="celular2"
                  variant="outlined"
                  onChange={formik.handleChange}
                  className={classes.text}
                  value={formik.values.celular2}
                />
               
                {/* <TextField
                  id="standard-basic"
                  label="Valor Domicilio"
                  name="valorDomicilio"
                  variant="outlined"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.valorDomicilio}
                  helperText={
                    formik.touched.valorDomicilio &&
                    formik.errors.valorDomicilio
                  }
                  error={
                    formik.touched.valorDomicilio &&
                    formik.errors.valorDomicilio
                      ? true
                      : false
                  }
                /> */}
                <TextField
                  id="standard-basic"
                  label="Valor Pedido Minimo"
                  name="valorPedidoMinimo"
                  variant="outlined"
                  type="number"
                  className={classes.text}
                  onChange={formik.handleChange}
                  value={formik.values.valorPedidoMinimo}
                  helperText={
                    formik.touched.valorPedidoMinimo &&
                    formik.errors.valorPedidoMinimo
                  }
                  error={
                    formik.touched.valorPedidoMinimo &&
                    formik.errors.valorPedidoMinimo
                      ? true
                      : false
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Rango en Metros"
                  name="rangoEnMetros"
                  variant="outlined"
                  type="number"
                  className={classes.text}
                  onChange={formik.handleChange}
                  value={formik.values.rangoEnMetros}
                  helperText={
                    formik.touched.rangoEnMetros && formik.errors.rangoEnMetros
                  }
                  error={
                    formik.touched.rangoEnMetros && formik.errors.rangoEnMetros
                      ? true
                      : false
                  }
                />
                  {/* <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                  <Select
                    style={{
                      marginTop: "1rem",
                      borderRadius: "7px",
                      height: "55px",
                    }}
                    color="#AC7A47"
                    options={categories}
                    labelField="Nombre"
                    valueField="Codigo"
                    onChange={(value) => setCat(value[0])}
                    values={[cat]}
                  /> */}               
            </Paper>
          </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <div  style={{marginBottom:20}}>
                <Grid container spacing={1}>
                {/* <Grid xs={12}>
                  <div>
                    <InputLabel id="demo-simple-select-label">
                      Distrito<span style={{color:"red"}}>*</span> :
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: "1rem",
                        borderRadius: "7px",
                        height: "55px",
                        textAlign:"center"
                      }}
                      disabled={true}
                      color="##AC7A47"
                      //options={deps}
                      labelField="Nombre"
                      valueField="Codigo"
                      //onChange={(value) => handleChange(value)}
                      values={[dep]}
                    />
                  </div>
                  </Grid>
                  <Grid xs={12}>
                  <div>
                    <InputLabel id="demo-simple-select-label" style={{marginTop:10}}>
                     Corregimiento<span style={{color:"red"}}>*</span>:
                    </InputLabel>
                    <Select
                      style={{
                        marginTop: "1rem",
                        borderRadius: "7px",
                        height: "55px",
                      }}
                      disabled={true}
                      color="#AC7A47"
                      //options={munis}
                      labelField="Nombre"
                      valueField="Codigo"
                      //onChange={(value) => setMunicipio(value)}
                      values={[mun]}
                    />
                  </div>
                  </Grid> */}
                                        
                  <Grid xs={12}>
                  <div>
                    <InputLabel id="demo-simple-select-label" style={{marginTop:10,marginBottom:10}}>
                     Direccion<span style={{color:"red"}}>*</span>:
                    </InputLabel>
                    <TextField id="outlined-basic" variant="outlined"
                    name="direccion" 
                     onChange={formik.handleChange}
                     value={formik.values.direccion}
                     helperText={formik.touched.direccion && formik.errors.direccion}
                     error={
                       formik.touched.direccion && formik.errors.direccion
                         ? true
                         : false
                     }
                     placeholder="Direccion completa" style={{width:"100%"}}/>
                  </div>                  
                  </Grid>
                  <Grid  xs={12}>
                  <div>
                    <InputLabel id="demo-simple-select-label" style={{marginTop:10,marginBottom:10}}>
                     Barrio<span style={{color:"red"}}>*</span>:
                    </InputLabel>
                    <TextField id="outlined-basic" variant="outlined" placeholder="Ingrese el barrio"
                    name="Barrio"
                    onChange={formik.handleChange}
                    value={formik.values.Barrio}
                    helperText={formik.touched.Barrio && formik.errors.Barrio}
                    error={
                      formik.touched.Barrio && formik.errors.Barrio
                        ? true
                        : false
                    }
                    style={{width:"100%"}} />
                  </div>
                  </Grid>
                  <Grid  xs={12}>
                  <div>                    
                  <InputLabel id="demo-simple-select-label" style={{marginTop:10,marginBottom:10}}>
                     Observaciones adicionales:
                    </InputLabel>
                    <TextField id="outlined-basic" multiline rows={5} inputProps={{maxLength: 250}} variant="outlined" onChange={(event)=>setObservacionesAd(event.target.value)} value={ObservacionesAd} placeholder="Ingrese las observaciones adicionales" style={{width:"100%"}} />
                  </div>
                  </Grid>
                  <Grid  xs={6}>
                  <div>
                    <InputLabel id="demo-simple-select-label" style={{marginTop:10,marginBottom:10}}>
                     Latitud<span style={{color:"red"}}>*</span>:
                    </InputLabel>
                    <TextField id="outlined-basic" variant="outlined" placeholder="Latitud"
                    RefLat={RefLat}
                     value={formik.values.latitud}
                     name="latitud"
                     onChange={formik.handleChange}              
                     helperText={formik.touched.latitud && formik.errors.latitud}
                     error={
                       formik.touched.latitud && formik.errors.latitud
                         ? true
                         : false
                     }
                      />
                  </div>
                  </Grid>
                  <Grid  xs={6}>
                  <div>                    
                  <InputLabel id="demo-simple-select-label" style={{marginTop:10,marginBottom:10}}>
                   Longuitud:
                    </InputLabel>
                    <TextField id="outlined-basic" variant="outlined" placeholder="Longuitud" 
                    value={formik.values.longitud}                    
                     name="longitud"
                     onChange={formik.handleChange}                     
                     helperText={formik.touched.longitud && formik.errors.longitud}
                     error={
                       formik.touched.longitud && formik.errors.longitud
                         ? true
                         : false
                     } />
                  </div>                  
                  </Grid> 
                  </Grid>                  
                </div>              
              </Paper>
            </Grid>         
        </Grid>
        <div style={{display:"flex",width:"100%",justifyContent:"center"}}>
        <Grid xs={4} >
        <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#AC7A47", color: "white" }}
                  className={classes.submit}
                >
                  {type==="crear"? "REGISTRAR TIENDA" :"ACTUALIZAR TIENDA"}
        </Button>
        </Grid>
        </div>        
        </form>
      </div>
    </div>
  );
};
