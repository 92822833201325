import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Grid,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Button,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Typography,
  Paper
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PeopleIcon from '@material-ui/icons/People';
import GetAppIcon from '@material-ui/icons/GetApp';
import Select from "react-dropdown-select";
import { GET_ORDERS_CUSTOMER,GET_ORDERS_DETAILS,GET_STORES,GET_ORDERS_FILTER_DATE_CUSTOMER  } from "../../../constants";
import AdminContext from '../../../context/admin/AdminContext';
import { Axios } from "../../../config/axios";
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import toast from "toasted-notes";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var DetailsOrd=[]
var detallesPedidos = []


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



function Row(props) {
  
  const { row } = props;
  const { index } = props;
  const { OrdersFilter } = props;
  const { setOrdersDetails } = props;
  const [open, setOpen] = React.useState(false);
  const [Details, setDetails] = useState([])
  const classes = useRowStyles();
  

  const getDetails=async()=>{
     setTimeout(() => {
      Axios.get(GET_ORDERS_DETAILS+row.NumeroPedido)
      .then((res) => {
        console.log("pedido: ",res.data);
        setDetails(res.data)
        if(res.data.length!==0){
          var cont=0;
          res.data.forEach(detail => {
            cont ++;
            detail.Precio=detail.Precio.toFixed(2);
          });
          if(cont===res.data.length){
            DetailsOrd.push(res.data)
            res.data.forEach(element => {
              detallesPedidos.push(element)
            });
          }
         
        } 
        if(index===OrdersFilter.length - 1){
          setOrdersDetails(DetailsOrd)
          console.log("pedidos",detallesPedidos);
        } 
      })
      .catch((err) => {
        console.log(err);
      });
     }, 1000);
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
        {row.NumeroPedido}
        </TableCell>
        <TableCell align="right">{row.Nombre}</TableCell>
        <TableCell align="right">{Number.parseFloat(row.Valor).toFixed(2)}</TableCell>
        <TableCell align="right">{row.FechaCreacion.replace('T'," ")}</TableCell>
        <TableCell align="right">{row.EstadoNombre}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Detalles del pedido 
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Cantidad</TableCell>
                    <TableCell align="right">Iva</TableCell>
                    <TableCell align="right">Precio</TableCell>                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Details.map((detail,i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {detail.Nombre}
                      </TableCell>
                      <TableCell>{detail.Cantidad}</TableCell>
                      <TableCell align="right">{Number.parseFloat(detail.Iva).toFixed(2)}</TableCell>
                      <TableCell align="right">
                        $ {Number.parseFloat(detail.Precio).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function OrdersCustomers({customer,setReporte}) {


const [Orders, setOrders] = useState([]);
const [OrdersFilter, setOrdersFilter] = useState([])
const [FilterValor, setFilterValor] = useState("")
const [stores, setStores] = useState([]);
const [loading, setLoading] = React.useState(true);
const [store, setStore] = useState("");
const [beforeDate, setBeforeDate] = React.useState(new Date());
const [nextDate, setNextDate] = React.useState(new Date());
const [OrdersDetails, setOrdersDetails] = useState([])
const { admin } = React.useContext(AdminContext);


console.log("este es el admin ",admin);
console.log(OrdersDetails);

const getStores = async () => {
  Axios.get(GET_STORES)
    .then((res) => {
      console.log(res.data);
      res.data.unshift({
        Codigo: -1,
        Nombre: "TODAS",
      });
      setStores(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

const handleStore=(store)=>{
  if(store.Nombre=="TODAS"){
    setOrdersFilter(Orders)
    return;
  }
  const result= Orders.filter((c)=>c.Nombre===store.Nombre)
  setOrdersFilter(result)

}

const handleValor=(opcion)=>{
  setFilterValor(opcion)
  console.log("opcion ",opcion);
  if(opcion.Nombre==="ORDENAR POR VALOR"){
    setOrdersFilter(Orders)
    return;
  }
  if(opcion.Nombre==="DE MENOR A MAYOR"){
    const copy = [...OrdersFilter];
    const result= copy.sort((a,b)=>a.Valor - b.Valor)
    setOrdersFilter(result);
    console.log("result ",result);
  }
  if(opcion.Nombre==="DE MAYOR A MENOR"){
    const copy = [...OrdersFilter];
    const result= copy.sort((a,b)=>a.Valor - b.Valor).reverse()
    setOrdersFilter(result);
    console.log("result ",result);
  }  
}

const handleFecha=(opcion)=>{
  console.log("opcion ",opcion);
  if(opcion.Nombre==="ORDENAR POR FECHA"){
    setOrdersFilter(Orders)
    return;
  }
  if(opcion.Nombre==="DE MENOR A MAYOR"){
    const copy = [...OrdersFilter];
    const result= copy.sort((a,b)=> new Date(a.Fecha) - new Date(b.Fecha))
    setOrdersFilter(result);
    console.log("result ",result);
  }
  if(opcion.Nombre==="DE MAYOR A MENOR"){
    const copy = [...OrdersFilter];
    const result= copy.sort((a,b)=>new Date(a.Fecha) - new Date(b.Fecha)).reverse()
    setOrdersFilter(result);
    console.log("result ",result);
  }  
}

/* const getDetails=async(orders)=>{
  var cont=0;
   orders.forEach(order => {
    setTimeout(function() {
      Axios.get(GET_ORDERS_DETAILS+order.NumeroPedido)
    .then((res) => { 
      console.log("Pedido: ",res.data);
        cont++;    
      if(res.data.length!==0){
        DetailsOrd.push(res.data)
      }
      if(cont===orders.length){
        setOrdersDetails(DetailsOrd)
      }
    })
    .catch((err) => {
      console.log(err);
    });
    });
} */

const getOrders = async () => {  
  Axios.get(GET_ORDERS_CUSTOMER+"fechaInicial=2020/01/01&fechaFinal=2021/11/11&codUsuario="+'1397')
    .then(async (res) => {
      console.log(res);
       var cont=0;
      res.data.forEach(detail => {
        cont ++;
        detail.valor=detail.valor.toFixed(2);
        // detail.Fecha=detail.Fecha.replace('T'," ");
      });
      if(cont===res.data.length){
        console.log("entraaa");
        setOrders(res.data)
        setOrdersFilter(res.data)
      } 
      setOrders(res.data)
      setOrdersFilter(res.data)
      //getDetails(res.data)
    })
    .catch((err) => {
      console.log(err);
    });
};


const filterDate = async () => {
  const valid = moment(moment(nextDate).format("YYYY-MM-DD")).isAfter(
    moment(beforeDate).format("YYYY-MM-DD")
  );
  const x = moment(nextDate).diff(moment(beforeDate), "minute");
  try {
    if (valid || x == 0) {
      console.log("valido");
      const initialDate = moment(beforeDate).format("YYYY-MM-DD");
      const finalDate = moment(nextDate).format("YYYY-MM-DD");
      Axios.get(
        `${GET_ORDERS_FILTER_DATE_CUSTOMER}${customer.CodUsuario}/${initialDate}/${finalDate}`
      )
        .then((res) => {
          console.log("respuesta horarios",res.data);
          setLoading(false);
          setOrdersFilter(res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      toast.notify("Las fechas no son validas");
    }
  } catch (err) {
    console.log(err);
  }
};

  useEffect(() => {
      getStores();
      getOrders();
  }, [OrdersDetails])
   

  const filename="Reporte_"+customer.Nombres+" "+customer.Apellidos;

  return (
    <Grid container spacing={3}>
      <div style={{display:"flex",justifyContent:"space-Between",alignItems:"center",width:"100%"}}>
      <Button
        variant="contained"
        color="primary"
        endIcon={
           <PeopleIcon />
        }
        onClick={() =>{ 
          setReporte(false)
          setOrdersDetails([])
          DetailsOrd=[]
         }}
        style={{
          backgroundColor: "#AC7A47",
          color: "white",
          margin: "1rem",
        }}
      >
        Usuarios
      </Button>
            <Select
              style={{
                borderRadius: "7px",
                height: "40px",
              }}
              color="#707070"
              options={stores}
              labelField="Nombre"
              valueField="Codigo"
              onChange={(value) => handleStore(value[0])}
              values={[
                 {
                      Nombre: "Filtrar por tienda",
                    },
              ]}
            />
            <Select
              style={{
                borderRadius: "7px",
                height: "40px",
              }}
              color="#707070"
              options={[{Nombre:"ORDENAR POR VALOR"},{Nombre:"DE MAYOR A MENOR"},{Nombre:"DE MENOR A MAYOR"}]}
              labelField="Nombre"
              valueField="Codigo"
              onChange={(value) => handleValor(value[0])}
              values={[
                 {
                      Nombre: "Ordenar por Valor",
                    },
              ]}
            />
            <Select
              style={{
                borderRadius: "7px",
                height: "40px",
              }}
              color="#707070"
              options={[{Nombre:"ORDENAR POR FECHA"},{Nombre:"DE MAYOR A MENOR"},{Nombre:"DE MENOR A MAYOR"}]}
              labelField="Nombre"
              valueField="Codigo"
              onChange={(value) => handleFecha(value[0])}
              values={[
                 {
                      Nombre: "Ordenar por Fecha",
                    },
              ]}
            />

      <ExcelFile element={<Button
      style={{
        backgroundColor: "#1f6f43",
        color: "white",
        margin: "1rem",
      }}
      endIcon={
        <GetAppIcon />
     }
      >Export excel</Button> } filename={filename}>
      <ExcelSheet data={Orders} name="Pedidos">
                    <ExcelColumn label="Numero Pedido" value="NumeroPedido"/>
                    <ExcelColumn label="Tienda" value="Nombre"/>
                    <ExcelColumn label="Valor" value="Valor"/>
                    <ExcelColumn label="Fecha" value="Fecha"/>
                    <ExcelColumn label="Estado" value="EstadoNombre"/>
      </ExcelSheet>
      <ExcelSheet data={detallesPedidos} name={`Detalles_De_Pedido`}>
        <ExcelColumn label="Numero de pedido" value="NumeroPedido"/>
        <ExcelColumn label="Nombre" value="Nombre"/>
        <ExcelColumn label="Cantidad" value="Cantidad"/>
        <ExcelColumn label="Iva" value="Iva"/>
        <ExcelColumn label="Precio" value="Precio"/>
      </ExcelSheet>
      {/* forma para descargar cada detalle de producto de forma individual  */}
      {/* {OrdersDetails.map((details,i)=>(
        console.log(details),
        <ExcelSheet data={details} name={`Pedido_${details[0].NumeroPedido}_Details`}>
        <ExcelColumn label="Numero de pedido" value="NumeroPedido"/>
        <ExcelColumn label="Nombre" value="Nombre"/>
        <ExcelColumn label="Cantidad" value="Cantidad"/>
        <ExcelColumn label="Iva" value="Iva"/>
        <ExcelColumn label="Precio" value="Precio"/>
        </ExcelSheet>
      ))} 
      
 */}
      </ExcelFile>
    </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="center" style={{ margin: "2rem" }} spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              id="date-picker-inline"
              label="Fecha Inicial"
              value={beforeDate}
              onChange={(date) => setBeforeDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              id="date-picker-inline"
              label="Fecha Final"
              value={nextDate}
              onChange={(date) => setNextDate(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Button
              variant="contained"
              style={{ color: "#fff", backgroundColor: "#0a82ec" }}
              onClick={filterDate}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider> 
      <div style={{width:"80%",display:"flex",justifyContent:"space-around"}}>
      <div>
      <Box component="h9" display="inline"  style={{fontWeight:'bold'}}>
        Nombre:&nbsp;
      </Box>
      <Box component="h9" display="inline" >
        {customer.Nombres} {customer.Apellidos}
      </Box>
      </div>    
      <div>
      <Box component="h9" display="inline"  style={{fontWeight:'bold'}}>
      Numero pedidos:&nbsp;
      </Box>
      <Box component="h9" display="inline" >
      {OrdersFilter.length}
      </Box>
      </div>
      </div>       
      <Grid item xs={12}>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Numero Pedido</TableCell>
            <TableCell align="right">Tienda</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell align="right">Fecha</TableCell>
            <TableCell align="right">Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {OrdersFilter.map((row,i) => (
            <Row key={i} index={i} row={row} setOrdersDetails={setOrdersDetails} OrdersFilter={OrdersFilter}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>
    </Grid>
  );
}
