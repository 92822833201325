export const URL_BASE = process.env.REACT_APP_URL_API;
export const GET_DEPARTMENTS = "api/Admin/Departments";
export const GET_MUNICIPALITIES = "api/Admin/Municipalities/";
export const CREATE_STORE = "api/Admin/CreateStore/";
export const DELETE_STORE = "api/Admin/Delete/Store";
export const LOGIN = "api/Admin/Login/";
export const GET_CATEGORIES = "api/Admin/Categories";
export const CREATE_CATEGORY = "api/Admin/CreateCategory/";
export const UPDATE_ORDEN_CATEGORY = "api/Admin/category/update/orden";
export const EDIT_CATEGORY = "api/Admin/Update/Category/";
export const DELETE_CATEGORY = "api/Admin/DeleteCategoria";
export const GET_CATEGORIES_STORE = "api/Admin/Categories/Store/";
export const CREATE_CATEGORY_STORE = "api/Admin/CreateCategory/Store";
export const GET_SCHEDULE_STORE = "api/Admin/Schedules/Store/";
export const CREATE_SCHEDULE_STORE = "api/Admin/CreateSchedule/Store/";
export const DELETE_SCHEDULE_STORE = "tienda/DeleteHorario?idHorario="
export const GET_PRODUCTS_STORE = "api/Admin/Products/Store/";
export const GET_PRODUCTS = "api/Admin/Products";
export const GET_STORES = "api/Admin/Stores";

export const GET_ORDERS_STORE = "api/Admin/Orders/Store/";
export const GET_ORDERS_CUSTOMER = "api/Admin/Customers/Orders?";
export const GET_CUSTOMER_TERM = "api/Admin/Customers/Term/";

export const UPDATE_PRODUCT_STATE = "api/Admin/Update/Product/State";
export const UPLOAD_FILE_PRODUCT = "api/Admin/Upload/File/Product"
export const CREATE_PRODUCT_STORE = "api/Admin/Create/Product/Store"
export const UPDATE_STORE_STATE = "api/Admin/Update/Store/State"
export const UPDATE_CATEGORY_STATE = "api/Admin/Update/Category/State"
export const UPDATE_CATEGORY_STORE_STATE = "api/Admin/Update/Category/Store/State"

export const UPDATE_STORE = "api/Admin/Update/Store"
export const UPLOAD_FILE_STORE = "api/Admin/Upload/File/Store"
export const GET_STORE = "api/Admin/Store/"
export const GET_REPORTS_DAY = "api/Admin/Dashboard/Reports/Day/"
export const GET_DATA_ORDERS_DAY = "api/Admin/Dashboard/Data/Orders/Day/"
export const UPDATE_PRODUCT_STORE = "api/Admin/Update/Product/Store/"
export const GET_PRODUCTS_STORE_CATEGORY = "api/Admin/Products/Store/Category/"
export const GET_ORDERS_DETAILS = "api/Admin/Orders/Details/"
export const GET_ENCRIPT = "api/Admin/Encript/"
export const CHANGE_ENTREGADO="Tienda/Cambiar/Entregado/"
export const GET_OPTIONS_DETAILS = "api/Admin/Details/Opcions/"
export const GET_ORDERS_PENDING = "api/Admin/Dashboard/Orders/Pending/Day/"
export const GET_STATES = "api/Admin/States/"
export const GET_ORDERS_FILTER_DATE = "api/Admin/Orders/Filter/Date/"
export const GET_ORDERS_FILTER_DATE_CUSTOMER = "api/Admin/Orders/Filter/Date/Customer/"
export const GET_CUSTOMERS_STORE = "api/Admin/Customers/Store/"
export const UPDATE_PRODUCT = "api/Admin/Update/Product/"

export const UPDATE_CATEGORY_STORE = "api/Admin/Update/Category/Store/"
export const UPDATE_SCHEDULE_STORE = "api/Admin/Update/Schedule/Store/"


// secciones
export const GET_SECCIONES = "api/Admin/Secciones"
export const DELETE_SECCION = "api/Admin/Delete/Seccion"
export const GET_CAT_OPCIONES = "api/Admin/CatOpciones"
export const CREATE_OPCION = "api/Admin/CreateOpcion"
export const DELETE_OPCION = "api/Admin/DeleteOpcion"
export const CREATE_SECCION_OPCIONES = "api/Admin/CreateSeccion"
export const GET_SECCIONES_PRODUCT = "api/Admin/Secciones/Product/"
export const UPDATE_SECCION = "api/Admin/Update/Seccion"

// productos super admin
export const GET_PRODUCTS_CATEGORY = "api/Admin/Products/Category/"

// productos
export const CREATE_PRODUCT = "api/Admin/Create/Product"
export const UPDATE_ORDEN_PRODUCTS = "api/Admin/update/OrdenProduct?"
export const STORES_PRODUCT = "api/Admin/Stores/Product/"
export const DELETE_PRODUCT = "api/Admin/DeleteProductStore"
export const PRODUCT_STORE = "api/Admin/Product/Store"
export const UPDATE_PRODUCT_STATE_STORE = "api/Admin/Update/Product/State/Store"

//estado pedidos 
export const GET_MOTIVOS_RECHAZO = "Pedido/ObtenerMotivosRechazo"
export const INSERT_MOTIVO_RECHAZO = "Pedido/InsertMotivoRechazo?"

export const IMAGE_URL="https://emartwebapi.celuwebdev.com/PascualPickAndGo/Images/productos/"


//modulos para pasarela de pago 
export const WSDETALLEPAGO = URL_BASE + 'api/Recaudo/detallePagoPasarela?codCliente=';
export const WSINFOCLIENT = URL_BASE + 'api/cliente/ClienteByCodigo?codigoCliente=';

export const URLPAIMENT = process.env.REACT_APP_URL_PAYMENT
export const SECRETKEY = process.env.REACT_APP_SECRET_KEY


