import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Button,
  InputLabel,
  Grid,
  Paper,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Axios } from "../../../../config/axios";
import {
  GET_CATEGORIES_STORE,
  UPLOAD_FILE_PRODUCT,
  CREATE_PRODUCT_STORE,
  UPDATE_PRODUCT_STORE,
  GET_CATEGORIES,
} from "../../../../constants";
import Select from "react-dropdown-select";
import toast from "toasted-notes";
import AdminContext from "../../../../context/admin/AdminContext";
import { DropzoneArea } from "material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
      width: "40ch",
    },
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  // paper: {
  //   padding: theme.spacing(2),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // },
  title: {
    flexGrow: 1,
    margin: "1rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export const ProductForm = ({ setForm, product, codCat }) => {
  const classes = useStyles();
  const { admin } = React.useContext(AdminContext);
  const [categories, setCategories] = useState([]);
  const [cat, setCat] = useState("");
  const [notCat, setNotCat] = useState(false);
  const [picante, setPicante] = useState([
    {
      nivelPicante: 0,
    },
    {
      nivelPicante: 1,
    },
    {
      nivelPicante: 2,
    },
    {
      nivelPicante: 3,
    },
  ]);
  const [vegano, setVegano] = useState([
    {
      label: "Si",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ]);
  const [vegetariano, setVegetariano] = useState(vegano[1].value);
  const [nivelPicante, setNivelPicante] = useState(picante[0].nivelPicante);
  const [codCategoria, setCodCategoria] = useState(product.CodCategoria);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(null);

  const getCategories = async () => {
    Axios.get(`${GET_CATEGORIES}?type=${0}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          var array=Array.from(res.data) 
          array=array.filter((e)=>e.Tipo===0);       
          setCategories(array);
          if (product) {
            let categoria = res.data.find(
              (c) => c.Codigo == product.CodCategoria
            );
            setCat(categoria);
            setCodCategoria(categoria.Codigo);
            setNivelPicante(product.NivelPicante);
            setVegetariano(product.Vegetariano);
          } else {
            setCat(res.data[0]);
            setCodCategoria(res.data[0].Codigo);
          }
        } else {
          setNotCat(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      nombre: product ? product.Nombre : "",
      precio: product ? product.Precio : "",
      descripcion:product ? product.Descripcion : "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("El Nombre es requerido"),
      precio: Yup.number().min(1, "EL precio deber ser mayor que cero"),
      descripcion: Yup.string().required( "La descripcion es requerida"),
    }),
    onSubmit: (values) => {
      console.log("precioo ",parseFloat(values.precio).toFixed(2));
      var precio=parseFloat(values.precio).toFixed(2)
      const data = {
        codTienda: -1,
        codCategoria: codCat,
        precio: precio,
        nombre: values.nombre,
        descripcion: values.descripcion,
        nivelPicante:0,
        vegetariano:0,
        agotado: false,
      };
      console.log(data);
      product ? updateProduct(data) : createProduct(data);
    },
  });

  const createProduct = (data) => {
    Axios.post(CREATE_PRODUCT_STORE, data)
      .then((res) => {
        console.log(res.data);
        setForm(false);
        // const fd = new FormData();
        // fd.append("image", image);
        // Axios.post(
        //   `${UPLOAD_FILE_PRODUCT}?CodTienda=${admin.codigo}&Codigo=${res.data.Codigo}`,
        //   fd
        // )
        //   .then((res) => {
        //     console.log(res);
        //     if (res.data.Estado == "Error") {
        //       toast.notify("No se pudo crear el producto");
        //     } else {
        //       toast.notify("Producto Creado Correctamente");
        //       setForm(false);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     toast.notify("No se pudo crear el producto");
        //   });
      })
      .catch((err) => {
        console.log(err);
        toast.notify("No se pudo crear el producto");
      });
  };

  const updateProduct = (data) => {
    data.codCategoria=codCategoria
    Axios.post(UPDATE_PRODUCT_STORE + product.Codigo, data)
      .then((res) => {
        if (image) {
          const fd = new FormData();
          fd.append("image", image);
          Axios.post(
            `${UPLOAD_FILE_PRODUCT}?CodTienda=${admin.codigo}&Codigo=${product.Codigo}`,
            fd
          )
            .then((res) => {
              console.log(res);
              if (res.data.Estado == "Error") {
                toast.notify("No se pudo actualizar el producto");
              } else {
                toast.notify("Producto Actualizado Correctamente");
                setForm(false);
              }
            })
            .catch((err) => {
              console.log(err);
              toast.notify("No se pudo actualizar el producto");
            });
        } else {
          console.log(res.data);
          toast.notify("Producto Actualizado Correctamente");
          setForm(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.notify("No se pudo actualizar el producto");
      });
  };

  const handleChange = (files) => {
    setImage(files[0]);
  };

  const calcCategoria=(codCartegoria)=>{
    console.log("codCartegoria ",codCartegoria);
    const index=categories.find((e)=>codCartegoria===e.Codigo);
    console.log(index);
    return index;
  }

  const onPhotoSelected = (e) => {
    if (e.target.files && e.target.files[0]) {
      const newFile = e.target.files[0];
      if (newFile.size <= 1000000) {
        console.log("entra");
        const reader = new FileReader();
        reader.onload = (e) => setUrl(reader.result);
        reader.readAsDataURL(newFile);
        setImage(newFile);
      } else {
        toast.notify("El peso de la imagen no es valido");
      }
    }
  };

  return (
    <div>
      <div className={classes.root}>
        {notCat ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "500px",
                }}
              >
                <Typography
                  component="h2"
                  variant="h4"
                  style={{ color: "#707070" }}
                  noWrap
                >
                  No tienes categorias creadas, !Crea Una!
                </Typography>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  component="h3"
                  variant="h6"
                  style={{ color: "#707070" }}
                  noWrap
                >
                  {product ? "Actualizar Producto" : "Nuevo Producto"}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                  className={classes.form}
                >
                  <TextField
                    id="standard-basic"
                    label="Nombre"
                    name="nombre"
                    variant="outlined"
                    autoFocus
                    onChange={formik.handleChange}
                    value={formik.values.nombre}
                    helperText={formik.touched.nombre && formik.errors.nombre}
                    error={
                      formik.touched.nombre && formik.errors.nombre
                        ? true
                        : false
                    }
                  />
                {product?
                <>  
                <h4>
                Categoria
              </h4>
                 { categories.length!==0?<Select
                      style={{
                        borderRadius: "7px",
                        height:"55px"                        
                      }}
                      color="#AC7A47"
                      options={categories}
                      valueField="Tipo"
                      labelField="Descripcion"
                      onChange={(value) => setCodCategoria(value[0].Codigo)} 
                      values={[calcCategoria(product.CodCategoria)]}
                    />:null}
                    </>
                  :null}
                  <TextField
                    id="standard-basic"
                    label="Descripcion"
                    name="descripcion"
                    variant="outlined"
                    autoFocus
                    inputProps={{
                      maxLength: 250,
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.descripcion}
                    helperText={formik.touched.descripcion && formik.errors.descripcion}
                    error={
                      formik.touched.descripcion && formik.errors.descripcion
                        ? true
                        : false
                    }
                  />
                  <TextField
                    id="standard-basic"
                    label="Precio"
                    variant="outlined"
                    name="precio"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.precio}
                    helperText={formik.touched.precio && formik.errors.precio}
                    error={
                      formik.touched.precio && formik.errors.precio
                        ? true
                        : false
                    }
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#AC7A47", color: "white" }}
                    className={classes.submit}
                  >
                    {product ? "Actualizar Producto" : "Registrar Producto"}
                  </Button>
                </form>
              </Paper>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                {product ? (
                  <>
                    {product.Img || url ? (
                      <>
                        <Typography
                          component="h2"
                          variant="h4"
                          style={{ color: "#707070" }}
                          noWrap
                        >
                          Imagen Actual
                        </Typography>
                        <img
                          style={{
                            width: "200px",
                            height: "200px",
                            margin: "1rem",
                          }}
                          className="img-product"
                          src={url ? url : product.Img}
                          onError={(e) => {
                            e.target.onerror = null;
                          }}
                        ></img>
                      </>
                    ) : (
                      <Typography
                        component="h2"
                        variant="h4"
                        style={{ color: "#707070" }}
                        noWrap
                      >
                        El producto no tiene imagen
                      </Typography>
                    )}

                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={onPhotoSelected}
                      id="input"
                      required
                      accept="image/*"
                    />
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: "#8900D8", color: "white" }}
                      className={classes.submit}
                      onClick={() => {
                        const input = document.getElementById("input");
                        input.click();
                      }}
                    >
                      Cambiar Imagen
                    </Button>
                  </>
                ) : (
                  <DropzoneArea
                    onChange={handleChange}
                    acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                    maxFileSize={1000000}
                    filesLimit={1}
                    dropzoneText="Subir Foto"
                  />
                )}
              </Paper>
            </Grid> */}
          </Grid>
        )}
      </div>
    </div>
  );
};
