import React, { useReducer } from "react";

import AdminReducer from "./AdminReducer";
import AdminContext from "./AdminContext";

import { LOGIN, LOGOUT, SAVE_NOTIFICATION } from "./types";

const AdminState = (props) => {
  const initialState = {
    admin: JSON.parse(localStorage.getItem("admin")) || null,
    notifications: JSON.parse(localStorage.getItem("notifications")) || [],
  };

  const [state, dispatch] = useReducer(AdminReducer, initialState);

  const login = (pdv) => {
    localStorage.setItem("admin", JSON.stringify(pdv));
    dispatch({
      type: LOGIN,
      payload: pdv,
    });
  };

  const logout = () => {
    localStorage.removeItem("admin");
    dispatch({
      type: LOGOUT,
      payload: null,
    });
  };

  const saveNotification = (n) => {
    localStorage.setItem("notifications", JSON.stringify(n));
    dispatch({
      type: SAVE_NOTIFICATION,
      payload: n,
    });
  };

  return (
    <AdminContext.Provider
      value={{
        admin: state.admin,
        notifications: state.notifications,
        count: state.count,
        login,
        logout,
        saveNotification,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;
