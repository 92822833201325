import React, { useState, useEffect } from 'react';
import Progress from '../../utils/Progress'
import axios from "axios";

//REDUX 

import { withRouter } from 'react-router-dom';

//IMAGENES

import success from '../../assets/icon/success.svg';
import error from '../../assets/icon/error.svg';
import noimage from '../../assets/img/generico.png';

var converter = require('hex2dec');
const query = require('query-string');

const PaymentResponse = () => {
    const [loader, setLoader] = useState(false);
    

    const [cliente, setCliente] = useState();
    const [params, setParams] = useState();
    const [respuestaPago, setRespuestaPago] = useState()
    const [total, setTotal] = useState()
    const [referencia, setReferencia] = useState(null)
    

    const ProcesarDatos = (codigoCliente, codigoPago, respuestaPago, total, referenciaPago) => {
        let codCliente = codigoCliente.slice(32)
        var hex  = codigoPago.toString();
        var codigoDePago = '';
        for (var n = 0; n < hex.length; n += 2) {
            codigoDePago += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        guardarRegistro(codCliente, codigoDePago, respuestaPago, total, referenciaPago)
    }

    const guardarRegistro = (codigoCliente, codigoDePago, respuestaPago, total, referenciaPago) => {
        let url = "https://emartwebapi.celuwebdev.com/PascualPickAndGo/api/Recaudo/RespuestaPago?"
        let sendUrl = url + `codCliente=${codigoCliente}&numeroDoc=${codigoDePago}&decision=${respuestaPago}&valor=${total}&transactionID=${referenciaPago}`
        console.log(sendUrl);
        axios.post(sendUrl)
            .then((res) => {
            console.log(res);
            })
            .catch((err) => {
            console.log(err);
        });
    }

        
    useEffect(() => {

    let URLactual = (new URL(document.location)).searchParams;
    
    let respuestaPago = URLactual.get("Estado")
    setRespuestaPago(respuestaPago) // dato mostrado en el html 

    let total = URLactual.get("TotalPagado")
    setTotal(total) //dato mostrado en el html

    let referenciaPago = URLactual.get("Oper") // referencia de pago en paguelo Facil
    setReferencia(referenciaPago) // datos mostrado en el html

    let codigoCliente = URLactual.get("CDSC") // codigo del cliente en CW
    
    let codigoPago = URLactual.get("PARM_1") // codigo del pago en CW
   
    ProcesarDatos(codigoCliente, codigoPago, respuestaPago, total, referenciaPago)
    
    },[])

    //url pruebas 
    //http://localhost:3000/respuesta/pago?PARM_1=3132333435363738&TotalPagado=10.0&Fecha=22/11/2021&Hora=08%3A35%3A32&Tipo=VISA&Oper=SANDBOX_LK-XWGKXEEEA4RF&Usuario=jhon+gonzalez&Email=jhonprogramacion%40gmail.com&Estado=Aprobada&Razon=VER+UNAVAILBLE&CMTN=10.0&CDSC=1053790872&CCLW=13009F83EB59E442773A1FC2EE5F362700EC26B5F79327C952BACC948365800CB814A19E9ADF464484014653B0BA0019BD5CD5226A246432ECCEAEDDD9F8A476
    
    return (
      <React.Fragment>
            <div>
                <div style={{textAlign:"center"}}>
                        <React.Fragment>
                            <img style={{width:"20%"}}
                                src={respuestaPago=="Aprobada" ? success : error}  
                                alt="icon error"
                                onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}/>

                            <div>    
                                { loader ? <Progress color={"#007daf"} /> : (                   
                                    <div> 
                                        <p><b>Transacción: </b><span style={{color:(respuestaPago=="Aprobada" ? "green" : "red")}}>{respuestaPago=="Aprobada" ? "APROBADA" : "RECHAZADA"}</span></p>
                                        <p><b>Referencia: </b>{referencia}</p>
                                        <p><b>Total: </b>{total} $</p><br/><br/>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                </div>
            </div> 
      </React.Fragment>
    )
    
  }

 

export default withRouter( (PaymentResponse) );